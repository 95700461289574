import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { APP_BAR_HEIGHT } from 'styles/constants';


export const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      boxSizing: 'border-box',
      top: APP_BAR_HEIGHT,
      position: 'relative',
      padding: theme.spacing(0),
      height: `calc(100% - ${APP_BAR_HEIGHT}px)`,
      maxWidth: '100%',
      backgroundColor: theme.palette.background.default,
    },
    title: { flex: 1, },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': { backgroundColor: alpha(theme.palette.common.white, 0.25), },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    inputRoot: { color: 'inherit', },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: { width: '20ch', },
    },
  };
});
