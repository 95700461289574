export enum EThresholdStandard {
  YUM_STANDARD = 'YUM_STANDARD',
  LOCAL_STANDARD = 'LOCAL_STANDARD',
}

export const LOCATION_TYPE = {
  MAKE_TABLE: 'MAKE_TABLE',
  FREEZER: 'FREEZER',
  FRIDGE: 'FRIDGE',
  WALK_IN_FREEZER: 'WALK_IN_FREEZER',
  WALK_IN_FRIDGE: 'WALK_IN_FRIDGE',
  SINK: 'SINK',
  PROOFER: 'PROOFER',
  ROOM_TEMP: 'ROOM_TEMP',
  OVEN_DECK: 'OVEN_DECK',
  DOOR: 'DOOR',
  MANAGER_DESK: 'MANAGER_DESK',
};

export const EQUIPMENT_TYPE = {
  MAKE_TABLE: 'MAKE_TABLE',
  FREEZER: 'FREEZER',
  FRIDGE: 'FRIDGE',
  WALK_IN_FREEZER: 'WALK_IN_FREEZER',
  WALK_IN_FRIDGE: 'WALK_IN_FRIDGE',
  PROOFER: 'PROOFER',
  ROOM_TEMP: 'ROOM_TEMP',
  HAND_WASHING_TIME: 'HAND_WASHING_TIME',
  HOT_WATER_AT_SINK: 'HOT_WATER_AT_SINK',
  HOT_HOLD: 'HOT_HOLD',
  CO2: 'CO2',
  HUMIDITY: 'HUMIDITY',
  OVEN_DECK: 'OVEN_DECK',
};
