import { TFindAllDeviceDataByIdResponse } from 'graphql/types';
import { EUnit } from 'hooks';
import { TDeviceDataExport, } from 'types/device';
import { TStoreExtended } from 'types/store';

import { EDeviceThingTypeNames, EMeasureUnit, isTempC } from './constants';
import { formatChartTimeDetail, formatTimeStamp } from './formatChartTime';
import { convertToFahrenheit } from './formatTemperature';


interface IDataExportProps {
  data: TFindAllDeviceDataByIdResponse;
  store: TStoreExtended;
  temperatureUnit?: EUnit;
  headers?: Array<{
    label: string;
    key: string;
  }>;
  thingType?: string;
}

type TExportCSV = {
headers: Array<{
  label: string;
  key: string;
}>,
data: TDeviceDataExport[]
}

export function exportCSV({
  data: dataProps,
  temperatureUnit = EUnit.Celsius,
  headers: headerProps = [],
  thingType,
  store,
}: IDataExportProps): TExportCSV {
  if (!dataProps?.findAllDeviceDataById?.items || !thingType) {
    return {
      headers: [],
      data: []
    };
  }
  const isCelsius = temperatureUnit === EUnit.Celsius;
  const tempUnit = isCelsius ? EMeasureUnit.TempC : EMeasureUnit.TempF;
  const headers = [
    ...headerProps,
    { label: 'Device Id', key: 'thingName' },
    { label: 'Store Id', key: 'champsNumber' },
    { label: 'Timestamp (Browser\'s timezone)', key: 'time' },
    { label: 'Timestamp (UTC)', key: 'time_utc' },
    { label: 'Timestamp (Store\'s timezone)', key: 'time_timezone' }
  ];

  switch (thingType) {
    case EDeviceThingTypeNames.SigFoxOrbitKTempProbe:
    case EDeviceThingTypeNames.SigFoxAyGaWacs:
    case EDeviceThingTypeNames.TestoSensorQsrTemp:
    case EDeviceThingTypeNames.TestoSensorT3Temp:
    case EDeviceThingTypeNames.TestoSensorWirelessTemp:
    case EDeviceThingTypeNames.TestoSensorT2Temp:
    case EDeviceThingTypeNames.SigFoxPipeTemperatureSimpleIndustry: {
      headers.push({
        label: `Temperature (${tempUnit})`,
        key: isCelsius ? 'value' : 'valueF',
      });
      break;
    }
    case EDeviceThingTypeNames.SigFoxCO2Sensor:
      headers.push({
        label: `Temperature (${tempUnit})`,
        key: isCelsius ? 'tempC' : 'tempF',
      });
      headers.push({ label: `CO2 (${EMeasureUnit.PPM})`, key: 'co2' });
      headers.push({ label: `Humidity (${EMeasureUnit.Percent})`, key: 'humidity' });
      break;

    case EDeviceThingTypeNames.TestoSensorQsrDoor:
    case EDeviceThingTypeNames.TestoSensorWirelessDoor:
    case EDeviceThingTypeNames.TestoSensorT2Door:
    case EDeviceThingTypeNames.TestoSensorT3Door:
    case EDeviceThingTypeNames.SigFoxDoorSensorSimplePack:
      headers.push({ label: 'Door status', key: 'value' });
      break;
    case EDeviceThingTypeNames.SigFoxPeopleSense: {
      headers.push({ label: `Handwashing Count (${EMeasureUnit.Times})`, key: 'value' });
      break;
    }
  }
  const sortedData = dataProps.findAllDeviceDataById.items
    .map(e => e)
    .sort((a, b) => a.timestamp - b.timestamp)
    .reduce<Record<number, TFindAllDeviceDataByIdResponse['findAllDeviceDataById']['items']>>((acc, cur) => {
      acc[cur.timestamp] = acc[cur.timestamp] ? [...acc[cur.timestamp], cur] : [cur];

      return acc;
    }, {});

  const { data } = Object.values(sortedData)
    .reduce<{data: TDeviceDataExport[], preHandwashingCount?: number}>((acc, deviceData) => {
      const { timestamp } = deviceData[0];
      const timeDetailFormatted = formatChartTimeDetail(timestamp);
      const timeObject = {
        time: timeDetailFormatted.fullTime,
        time_utc: formatTimeStamp(timestamp, 'UTC'),
        time_timezone: formatTimeStamp(timestamp, store.timeZone ?? 'UTC'),
      };
      let value: string | number = '';
      if (thingType === EDeviceThingTypeNames.SigFoxPeopleSense) {
        const { preHandwashingCount } = acc;
        const { value: countAccumulator } = deviceData[0];

        if (!preHandwashingCount || !countAccumulator) {
          return {
            data: acc.data,
            preHandwashingCount: preHandwashingCount || +countAccumulator
          };
        }
        value = preHandwashingCount > +countAccumulator ? +countAccumulator : +countAccumulator - preHandwashingCount;

        return {
          data: [
            ...acc.data,
                {
                  ...deviceData[0],
                  ...timeObject,
                  value,
                } as TDeviceDataExport
          ],
          preHandwashingCount: +countAccumulator
        };
      }
      else if (thingType === EDeviceThingTypeNames.SigFoxCO2Sensor) {
        const co2 = deviceData.find(e => e.equipmentType === 'CO2')?.value;
        const humidity = deviceData.find(e => e.equipmentType === 'HUMIDITY')?.value;
        const tempC = deviceData.find(e => e.equipmentType === 'ROOM_TEMP')?.value;

        return {
          data: [
            ...acc.data,
            {
              ...deviceData[0],
              ...timeObject,
              tempF: convertToFahrenheit(tempC as string),
              tempC,
              co2,
              humidity
            } as TDeviceDataExport
          ]
        };
      }

      return {
        data: [
          ...acc.data,
            {
              ...deviceData[0],
              ...timeObject,
              valueF: deviceData[0].measureUnit && isTempC(deviceData[0].measureUnit) ? convertToFahrenheit(deviceData[0].value) : null
            } as TDeviceDataExport
        ]
      };
    }, { data: [] });

  return {
    headers,
    data,
  };
}
