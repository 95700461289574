import { useQuery } from '@apollo/client';
import { SelectChangeEvent, Stack, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { IStoreExtended, TAuthenticatedUser } from '@pizza-hut/hutbot-mapp-sdk-js';
import {
  Button, EIconTypes, Icon, Input, useTableData
} from '@pizza-hut/hutbot-ui-components';
import { TUnControlColumn } from '@pizza-hut/hutbot-ui-components/build/components/UnControlTable/utils/types';
import clsx from 'clsx';
import { round } from 'lodash';
import {
  KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import TagManager from 'react-gtm-module';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Header from 'components/Header/Header';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { ROUTES } from 'features/App/constants';
import { FIND_ALL_DEVICES_BY_CHAMP_NUMBERS } from 'graphql/queries';
import { TFindAllDevicesByChampNumbersResponse } from 'graphql/types';
import {
  useDrawer, useLocalization, useScreenSize, ThresholdProvider
} from 'hooks';
import {
  TLatestBaseDeviceData,
  TLatestCo2DeviceData,
  TLatestDeviceData,
  TLatestDeviceDataUI,
  TLatestDeviceUI,
  TLatestDeviceValue
} from 'types/device';
import {
  EDeviceICon,
  EDeviceThingTypeNames,
  EHumanReadableDeviceThingTypeNames,
  i18NCommonContext
} from 'utils/constants';

import Detail from './Detail';
import DetailDrawer from './Detail/DetailDrawer';
import VisualizeIoTDataV2 from './VisualizeIoTDataV2';


const useStyles = makeStyles((theme: Theme) => ({
  dashboardContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  searchInput: { flexGrow: 1 },
  rootContainer: {
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
  detailContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    flexDirection: 'column',
  },
  scrollContainer: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 66px)',
      overflow: 'auto',
    }
  }
}));

const searchFields = [
  'thingName',
  'searchContent',
  'thingTypeName',
];
const searchFieldList: TUnControlColumn<TLatestDeviceUI>[] = searchFields.map((field) => (
  {
    title: field,
    field,
    searchable: true
  }
));

export type TDashboardProps = {
    stores: IStoreExtended[];
    user: TAuthenticatedUser | null;
    signOut?(): void;
    defaultChampsNumber?: string;
    onSelectStore: (champsNumber: string) => void;
}

export function Dashboard({
  signOut, stores, defaultChampsNumber, onSelectStore
}: TDashboardProps) {
  const location = useLocation();
  const { translate } = useLocalization();
  const { champsNumber, deviceId } = useParams<{ champsNumber: string, deviceId: string }>();
  const deviceIdRef = useRef<string>(deviceId);
  const activeWidgetRef = useRef<number>(-1);
  const [selectedChampsNumber, setSelectedChampsNumber] = useState('');
  const history = useHistory();
  const inputRef = useRef<any>(null);
  const classes = useStyles();
  const [activeWidget, setActiveWidget] = useState(activeWidgetRef.current);
  const { drawerOpen, toggleDrawer, setDrawerOpen } = useDrawer({});
  const { isMobileUI } = useScreenSize();
  const { loading, data } = useQuery<TFindAllDevicesByChampNumbersResponse>(FIND_ALL_DEVICES_BY_CHAMP_NUMBERS, {
    variables: { champsNumber },
    skip: !selectedChampsNumber
  });

  const updateUrl = useCallback((champsNumber: string, thingName: string) => {
    history.push(`${ROUTES.DASHBOARD}/${champsNumber}/${thingName}`);
  }, [history]);

  const updateWidgetSelection = useCallback((index) => {
    activeWidgetRef.current = index;
    setActiveWidget(index);
  }, [setActiveWidget]);

  useEffect(() => {
    /** Update the selected champsNumber when the champsNumber path changes*/
    if (champsNumber && champsNumber !== selectedChampsNumber) {
      setSelectedChampsNumber(champsNumber);
      onSelectStore(champsNumber);
    }
    if (!champsNumber && !selectedChampsNumber && defaultChampsNumber && stores.some(store => store.champsNumber === defaultChampsNumber)) {
      setSelectedChampsNumber(defaultChampsNumber);
      onSelectStore(defaultChampsNumber);
      history.replace(`${ROUTES.DASHBOARD}/${defaultChampsNumber}`);

      return;
    }
    if (champsNumber && !selectedChampsNumber) {
      setSelectedChampsNumber(champsNumber);
      onSelectStore(champsNumber);

      return;
    }
    if (!champsNumber && stores.length) {
      const newChampsNumber = stores[0].champsNumber;
      history.replace(`${ROUTES.DASHBOARD}/${newChampsNumber}`);
      setSelectedChampsNumber(newChampsNumber);
      onSelectStore(newChampsNumber);

      return;
    }
  }, [champsNumber, history, stores, selectedChampsNumber, defaultChampsNumber, onSelectStore]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: location.pathname + location.search,
      },
    });
  }, [location.pathname, location.search]);

  const onStoreChanged = useCallback((event: SelectChangeEvent) => {
    const newChampsNumber = event.target.value;
    history.push(`${ROUTES.DASHBOARD}/${newChampsNumber}`);
    setSelectedChampsNumber(newChampsNumber);
    onSelectStore(newChampsNumber);
    updateWidgetSelection(-1);
  }, [history, updateWidgetSelection, onSelectStore]);


  const renderDevices: TLatestDeviceUI[] = useMemo(() => {
    const devices = data?.findAllDevicesByChampNumbers?.items || [];

    return devices.slice().sort((d1, d2) => {
      const byLocation = d1?.attributes?.Location?.localeCompare(d2?.attributes?.Location);
      const byDeviceName = d1?.thingTypeName.localeCompare(d2?.thingTypeName);

      return byLocation !== 0 ? byLocation : byDeviceName;
    }).map(device => {
      const commonProps = {
        thingName: device.thingName,
        thingTypeName: device.thingTypeName,
        searchContent: `${EHumanReadableDeviceThingTypeNames[device.thingTypeName]} ${device.attributes?.Location}`,
        attributes: device.attributes,
      };
      if (device.latestDeviceData) {
        const data: TLatestDeviceData = JSON.parse(device.latestDeviceData);
        let value: TLatestDeviceValue[] = [];
        switch (device.thingTypeName) {
          case EDeviceThingTypeNames.SigFoxOrbitKTempProbe:
          case EDeviceThingTypeNames.SigFoxAyGaWacs:
          case EDeviceThingTypeNames.TestoSensorQsrTemp:
          case EDeviceThingTypeNames.TestoSensorT3Temp:
          case EDeviceThingTypeNames.TestoSensorWirelessTemp:
          case EDeviceThingTypeNames.TestoSensorT2Temp:
          case EDeviceThingTypeNames.SigFoxPipeTemperatureSimpleIndustry: {
            const valueObject = data as TLatestBaseDeviceData;
            value = [
              {
                icon: EDeviceICon.Temperature,
                value: String(round(+valueObject.value, 2)),
                title: translate(`${i18NCommonContext}.temperature`, 'Temperature'),
              }];
            break;
          }
          case EDeviceThingTypeNames.SigFoxCO2Sensor: {
            const valueObject = data as TLatestCo2DeviceData;
            value = [
              {
                icon: EDeviceICon.CO2,
                value: `${valueObject.CO2.value } ${ valueObject.CO2.measureUnit}`,
                title: 'CO2',
              }];
            if (valueObject.CO2_BASELINE) {
              value.push({
                icon: EDeviceICon.CO2,
                value: `${valueObject.CO2_BASELINE.value } ${ valueObject.CO2_BASELINE.measureUnit}`,
                title: translate(`${i18NCommonContext}.co2Baseline`, 'CO2 Baseline'),
              });
            }
            if (valueObject.ROOM_TEMP) {
              value.push({
                icon: EDeviceICon.Temperature,
                value: String(round(+valueObject.ROOM_TEMP.value, 2)),
                title: translate(`${i18NCommonContext}.temperature`, 'Temperature'),
              });
            }
            if (valueObject.HUMIDITY.value) {
              value.push({
                icon: EDeviceICon.Humidity,
                value: `${valueObject.HUMIDITY.value } ${ valueObject.HUMIDITY.measureUnit}`,
                title: translate(`${i18NCommonContext}.humidity`, 'Humidity'),
              });
            }
            break;
          }
          case EDeviceThingTypeNames.TestoSensorQsrDoor:
          case EDeviceThingTypeNames.TestoSensorWirelessDoor:
          case EDeviceThingTypeNames.TestoSensorT2Door:
          case EDeviceThingTypeNames.TestoSensorT3Door:
          case EDeviceThingTypeNames.SigFoxDoorSensorSimplePack: {
            value = [{
              value: `${(data as TLatestBaseDeviceData).value }` || 'Close',
              title: translate(`${i18NCommonContext}.doorStatus`, 'Door Status'),
              icon: EDeviceICon.DoorStatus,
            }];
            break;
          }
          case EDeviceThingTypeNames.SigFoxPeopleSense: {
            const valueObject = data as TLatestBaseDeviceData;
            value = [{
              icon: EDeviceICon.CO2,
              value: `${valueObject.value} ${valueObject.measureUnit}`,
              title: translate(`${i18NCommonContext}.handwashingCount`, 'Handwashing Count'),
            }];
            break;
          }
          default:
            break;
        }
        const latestDeviceDataObject: TLatestDeviceDataUI = {
          ...data,
          value: [...value],
          timestamp: data.timestamp
        };

        return {
          ...commonProps,
          latestDeviceDataObject
        } as TLatestDeviceUI;
      }

      return {
        ...commonProps,
        latestDeviceDataObject: null,
      } as TLatestDeviceUI;

    });
  }, [data, translate]);


  const {
    renderData, onSearch, updateData, searchValue
  } = useTableData<TLatestDeviceUI>({
    data: renderDevices,
    columns: searchFieldList,
    rowsPerPage: 10,
    showAll: true,
    showPagination: false,
  });
  const { data: dataRenders } = renderData;
  useEffect(() => {
    if (updateData && renderDevices) {
      updateData(renderDevices);
      onSearch('');
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [renderDevices, updateData, onSearch]);

  useEffect(() => {
    if (deviceIdRef.current && dataRenders.length && drawerOpen === undefined) {
      const foundIndex = dataRenders.findIndex(device => device?.thingName === deviceIdRef.current);
      if (foundIndex >= -1) {
        updateWidgetSelection(foundIndex);
        setDrawerOpen(true);
        updateUrl(champsNumber, deviceIdRef.current);
      }
      deviceIdRef.current = '';
    }
  }, [champsNumber, dataRenders, drawerOpen, updateWidgetSelection, setDrawerOpen, updateUrl]);

  const handleOnKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSearchClick();
    }
  };

  const onSearchClick = () => {
    if (inputRef.current?.value !== searchValue) {
      updateWidgetSelection(-1);
      onSearch(inputRef.current.value);
    }
  };

  const onClear = useCallback(() => {
    if (inputRef.current?.value) {
      inputRef.current.value = '';
      onSearch('');
    }
    updateWidgetSelection(-1);
  }, [onSearch, inputRef, updateWidgetSelection]);

  const onWidgetSelect = useCallback((index) => {
    updateWidgetSelection(index);
    if (dataRenders?.length && index > -1) {
      const device = dataRenders[index];
      if (device && champsNumber) {
        updateUrl(champsNumber, device.thingName);
      }
    }
    setDrawerOpen(true);
  }, [updateWidgetSelection, dataRenders, setDrawerOpen, champsNumber, updateUrl]);

  useEffect(() => {
    if (activeWidgetRef?.current === -1 && dataRenders?.length) {
      const device = dataRenders?.find(device => device?.latestDeviceDataObject);
      if (device && champsNumber && device.attributes.ChampsNumber === champsNumber) {
        updateUrl(champsNumber, device.thingName);
      }
    }
  }, [updateUrl, activeWidget, dataRenders, champsNumber]);

  const activeIndex = useMemo(() => {
    if (activeWidgetRef?.current === -1 && dataRenders) {
      if (deviceIdRef.current) {
        const foundIndex = dataRenders.findIndex(device => device?.thingName === deviceIdRef.current);
        if (foundIndex >= 0) {
          return foundIndex;
        }
      }

      return dataRenders.findIndex(device => device?.latestDeviceDataObject);
    }

    return activeWidget;
  }, [activeWidget, dataRenders]);

  const store: IStoreExtended | undefined = useMemo(() => {
    if (champsNumber) {
      return stores.find(store => store.champsNumber === champsNumber);
    }
  }, [champsNumber, stores]);

  return (
    <ThresholdProvider>
      <Header stores={stores} signOut={signOut} champsNumber={selectedChampsNumber}
        onSelectStore={onStoreChanged}/>
      <div className={classes.dashboardContainer}>
        <Grid container classes={{ root: classes.rootContainer }}>
          <Grid item xs={12} md={6} lg={6} sx={{ p: { md: 3, xs: 2 } }} className={clsx('not-print', classes.scrollContainer)}>
            {loading ? null : <Stack direction={'row'} sx={{ mb: 3 }}>
              <Input
                inputRef={inputRef}
                defaultValue={''}
                // onChange={handleChampNumberChange}
                className={classes.searchInput}
                placeholder={translate(`${i18NCommonContext}.searchByKeyword`, 'Search by keyword')}
                disabled={loading}
                onKeyUp={handleOnKeyUp}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon type={EIconTypes.ICON_SEARCH}/>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onSearchClick}
                sx={{ mx: 1 }}
                isLoading={loading}
                disabled={loading}
              >
                {translate(`${i18NCommonContext}.search`, 'Search')}
              </Button>
              <Button
                variant="text"
                color="secondary"
                size="large"
                onClick={onClear}
                disabled={loading}
                sx={{ mx: 0 }}
              >
                {translate(`${i18NCommonContext}.clear`, 'Clear')}
              </Button>
            </Stack>}
            {!loading && !renderDevices.length && <p>{translate(`${i18NCommonContext}.noDataFound`, 'No Data Found')}</p>}
            <Grid container spacing={2}>
              {loading ? <LoadingOverlay/> :
                dataRenders.map(({
                  thingName,
                  thingTypeName,
                  attributes,
                  latestDeviceDataObject
                }, index) => {
                  return <Grid item xs={12} md={6} lg={6} onClick={() => onWidgetSelect(index)}
                    key={thingName}>
                    <VisualizeIoTDataV2
                      isActive={activeIndex === index}
                      thingName={thingName}
                      thingTypeName={thingTypeName}
                      location={attributes.Location ?? translate(`${i18NCommonContext}.noLocation`, 'No Location')}
                      champsNumber={attributes.ChampsNumber}
                      deviceId={attributes.DeviceId}
                      latestDeviceData={latestDeviceDataObject}
                    />
                  </Grid>;
                })
              }
            </Grid>
          </Grid>
          {
            activeIndex >= 0 && isMobileUI && store ?
              <DetailDrawer store={store} device={dataRenders[activeIndex]} open={Boolean(drawerOpen)}
                toggleDrawer={toggleDrawer}/> : null
          }
          {
            !loading && dataRenders.length && store?
              <Grid item xs={12} md={6} lg={6}
                className={clsx('desktop-only', classes.detailContainer, classes.scrollContainer)}>
                {activeIndex >= 0 && <Detail store={store} device={dataRenders[activeIndex]}/>}
              </Grid>
              : null
          }
        </Grid>
      </div>
    </ThresholdProvider>
  );
}
