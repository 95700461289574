import { Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  Area,
  AreaChart as LibAreaChart,
  AreaProps,
  Brush,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import ChartTooltip from '../ChartTooltip';


export type TTemperatureChartProps = {
    title?: string,
    tooltipSuffix?: string,
    data: any;
    dataKey: string[],
    tooltipDataKey?: string,
    dataFill?: Record<string, string>
    type?: AreaProps['type'],
    showBrush?: boolean,
    domainPadding?: number,
    minValue?: number,
    maxValue?: number,
};

export function AreaChart({
  type = 'monotone',
  data,
  title,
  dataKey,
  tooltipSuffix,
  dataFill,
  tooltipDataKey,
  showBrush = true,
  minValue,
  maxValue
}: TTemperatureChartProps) {
  const theme = useTheme();

  return (
    <>
      {title ?
        <Typography component={'p'} sx={{ fontWeight: 600 }}>
          {title}
        </Typography> : null
      }
      <ResponsiveContainer width="100%" height="90%">
        <LibAreaChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="label"/>
          <YAxis/>
          <Tooltip content={<ChartTooltip dataKey={tooltipDataKey ? tooltipDataKey : dataKey[0]}
            suffix={tooltipSuffix}
            minValue={minValue}
            maxValue={maxValue}/>}/>
          {
            dataKey.map((key) => (
              <Area type={type} dataKey={key} key={key} stroke={theme.palette.warning.main}
                fill={dataFill?.[key] ? dataFill[key] : theme.palette.warning.light}
                fillOpacity={0.5}
              />))
          }

          {minValue || minValue === 0 ? <ReferenceLine y={minValue} stroke={theme.palette.success.main} alwaysShow/> : null}
          {maxValue || maxValue === 0 ? <ReferenceLine y={maxValue} stroke={theme.palette.success.main} alwaysShow/> : null}

          {showBrush && <Brush dataKey="time" height={30} stroke={theme.palette.secondary.light}/>}
        </LibAreaChart>
      </ResponsiveContainer>
    </>
  );
}
