import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Card, CardContent, Grid, Typography
} from '@mui/material';
import {
  AutoComplete,
  DateRangePicker,
  Button,
} from '@pizza-hut/hutbot-ui-components';
import moment, { Moment } from 'moment';
// eslint-disable-next-line import/order
import {
  SyntheticEvent, useCallback, useEffect, useMemo, useState
} from 'react';

/** Utils **/
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import Header from 'components/Header/Header';
import {
  FIND_ALL_DEVICE_DATA_BY_ID,
  FIND_ALL_DEVICES_BY_CHAMP_NUMBERS,
} from 'graphql/queries';
import {
  TFindAllDeviceDataByIdResponse,
  TFindAllDevicesByChampNumbersResponse,
} from 'graphql/types';
import { useLocalization } from 'hooks';
import { TDeviceSelectOptions } from 'types/device';
import { TStoreExtended, TStoreSelect } from 'types/store';
import {
  EHumanReadableDeviceThingTypeNames,
  i18NCommonContext,
} from 'utils/constants';
import { getEndUnix, getStartUnix } from 'utils/formatChartTime';

import DataExportDoc from './data-export-doc';
import useStyles from './data-export.styles';


export type TDataExportState = {
  store: TStoreSelect | null;
  device: TDeviceSelectOptions | null;
  selectedDateFrom: string | null;
  selectedDateTo: string | null;
  filteredTo: Moment | null;
  filteredFrom: Moment | null;
  cleanForm: boolean;
};
interface IDataExportProps {
  stores: TStoreExtended[];
  signOut?(): void;
  defaultChampsNumber?: string;
}
export const DataExport = ({
  stores,
  signOut,
  defaultChampsNumber,
}: IDataExportProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { translate } = useLocalization();
  const [champsNumber, setChampsNumber] = useState<string>('');
  const {
    loading: devicesLoading,
    error: devicesError,
    data: devicesData,
  } = useQuery<TFindAllDevicesByChampNumbersResponse>(
    FIND_ALL_DEVICES_BY_CHAMP_NUMBERS,
    {
      variables: { champsNumber },
      skip: !champsNumber,
    },
  );

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: location.pathname + location.search,
      },
    });
  }, [location.pathname, location.search]);

  const [disabledDownload, setDisabledDownload] = useState(true);

  const [
    getData,
    { data: exportData, error: exportError, loading: exportLoading },
  ] = useLazyQuery<TFindAllDeviceDataByIdResponse>(FIND_ALL_DEVICE_DATA_BY_ID);

  const currentStore = defaultChampsNumber
    ? stores.find((store) => store.champsNumber === defaultChampsNumber)
    : null;
  const [state, setState] = useState<TDataExportState>({
    store: null,
    device: null,
    selectedDateFrom: null,
    selectedDateTo: null,
    filteredTo: moment(),
    filteredFrom: moment(),
    cleanForm: true,
  });

  const {
    store, device, filteredTo, filteredFrom
  } = state;

  useEffect(() => {
    //update store when current store changed
    if (currentStore) {
      setState((prevState) => ({
        ...prevState,
        store: {
          ...currentStore,
          value: currentStore.champsNumber,
          label: `${currentStore.storeName} (${currentStore.champsNumber})`,
        },
      }));
    }
  }, [currentStore]);

  const handleStoresChanged = (
    e: SyntheticEvent,
    selectedStore: TStoreSelect,
  ) => {
    if (selectedStore) {
      setDisabledDownload(true);
      setChampsNumber(selectedStore.champsNumber);
      setState((prev) => ({
        ...prev,
        store: selectedStore,
        device: null,
      }));
    }
  };

  const handleDevicesChanged = (
    e: SyntheticEvent,
    selectedDevice: TDeviceSelectOptions,
  ) => {
    if (selectedDevice) {
      setDisabledDownload(true);
      setState((prevState) => ({
        ...prevState,
        device: selectedDevice,
      }));
    }
  };

  const storeOptions: TStoreSelect[] = useMemo(() => {
    return stores.map((store) => ({
      ...store,
      value: store.champsNumber,
      label: `${store.storeName} (${store.champsNumber})`,
    }));
  }, [stores]);

  const deviceOptions: TDeviceSelectOptions[] = useMemo(() => {
    const devices = devicesData?.findAllDevicesByChampNumbers?.items || [];

    return devices
      .map((device) => ({
        label: `${device.attributes.Location} - ${
          EHumanReadableDeviceThingTypeNames[device.thingTypeName] ||
          device.thingTypeName
        } - ${device.thingName}`,
        value: device.thingName,
        thingType: device.thingTypeName,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
  }, [devicesData]);

  const handleExportClick = useCallback(() => {
    if (device && filteredFrom && filteredTo) {
      getData({
        variables: {
          deviceId: device.value,
          limit: 10000,
          dateRange: {
            startDate: getStartUnix(filteredFrom),
            endDate: getEndUnix(filteredTo),
          },
          skip: !device.value,
        },
      });
      setDisabledDownload(false);
    }
  }, [device, filteredFrom, filteredTo, getData]);

  const handleDateRangeChanged = useCallback(
    (value: {from: Moment | null; to: Moment | null}) => {
      setDisabledDownload(true);
      setState((prev) => ({
        ...prev,
        filteredFrom: value.from,
        filteredTo: value.to,
      }));
    },
    [setState],
  );

  return (
    <>
      <Header signOut={signOut} showStoreSelect={false} />
      <Grid container className={classes.root} spacing={2} direction="column">
        <Grid item className={classes.item}>
          <Card variant="elevation">
            <CardContent className={classes.card}>
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textPrimary"
                  gutterBottom>
                  {translate(`${i18NCommonContext}.filters`, 'Filters')}
                </Typography>
              </Grid>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <AutoComplete
                    inputProps={{
                      placeholder: translate(
                        `${i18NCommonContext}.store`,
                        'Store',
                      ),
                    }}
                    options={storeOptions}
                    onChange={handleStoresChanged}
                    disabled={!storeOptions}
                    value={store}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    noOptionsText={translate(
                      `${i18NCommonContext}.noOptions`,
                      'No Options',
                    )}
                  />
                </Grid>
                <Grid item>
                  <AutoComplete
                    inputProps={{
                      placeholder: translate(
                        `${i18NCommonContext}.device`,
                        'Device',
                      ),
                    }}
                    options={deviceOptions}
                    onChange={handleDevicesChanged}
                    disabled={devicesLoading}
                    isLoading={devicesLoading}
                    value={device}
                    noOptionsText={translate(
                      `${i18NCommonContext}.noOptions`,
                      'No Options',
                    )}
                  />
                </Grid>
                <Grid item>
                  <DateRangePicker
                    onChange={handleDateRangeChanged}
                    disableFuture
                    value={{
                      from: moment(filteredFrom),
                      to: moment(filteredTo),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.root} spacing={2}>
        <Grid item md={4} xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.buttonContainer}>
            {exportData && !disabledDownload && store ? (
              <DataExportDoc
                data={exportData}
                store={store}
                thingType={device?.thingType}
                fileName={`export-${champsNumber}-${device?.value}.csv`}
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleExportClick}
                disabled={
                  !device || exportLoading || !filteredTo || !filteredFrom
                }
                isLoading={exportLoading}>
                {translate(
                  `${i18NCommonContext}.btn.generateReport`,
                  'Generate report',
                )}
              </Button>
            )}
          </Grid>
          {(exportError || devicesError) && (
            <Typography variant="subtitle2" color="error">
              {exportError || devicesError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
