import { TThreshold } from 'types/threshold';

import { EThresholdStandard } from './constant';
import { standardizeLocationType } from './standardizeLocationType';


export function getEquipmentThresholdByType(
  thresholds: TThreshold[],
  equipmentType: string,
  champsNumber: string,
  location: string
): TThreshold | undefined {
  const equipmentThreshold = thresholds.find(
    (t) =>
      t.equipmentType === equipmentType &&
      t.champsNumber === champsNumber &&
      standardizeLocationType(t.location) === location
  );

  if (equipmentThreshold) {
    return equipmentThreshold;
  }

  return thresholds.find(
    (t) =>
      t.equipmentType === equipmentType &&
      t.standardType === EThresholdStandard.YUM_STANDARD
  );
}
