import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';

import { withTemperatureUnit } from 'hooks/useTemperatureUnit';
import { IDataExportDocProps } from 'types/common';
import { exportCSV } from 'utils/export-csv';


function QuickExport({
  data: dataProps, temperatureUnit, fileName, thingType, store
}: IDataExportDocProps) {
  const dataExport = useMemo(() => {
    return exportCSV({
      data: dataProps, temperatureUnit, thingType, store
    });
  }, [dataProps, store, temperatureUnit, thingType]);

  const { headers, data } = dataExport;

  return (
    <CSVLink
      data={data}
      filename={fileName}
      headers={headers}
      target="_blank"
    >
      <IconButton
        aria-label="Download"
        size="medium"
      >
        <DownloadIcon fontSize="inherit" />
      </IconButton>
    </CSVLink>
  );
}
export default withTemperatureUnit(QuickExport);
