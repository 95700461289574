import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import { EDeviceICon } from '../../utils/constants';
import TemperatureDisplay from '../TemperatureDisplay/TemperatureDisplay';
import ThingIcon from '../ThingIcon/ThingIcon';


interface IRenderThingValue {
    className?: string,
    value: string,
    icon?: EDeviceICon
}

const ThingValue = ({ className, value, icon }: IRenderThingValue) => {
  return <Stack direction={'row'} alignItems={'center'} sx={{ mb: 0.5 }}>
    {icon && <ThingIcon icon={icon}/>}
    {
      icon && icon === EDeviceICon.Temperature ? <TemperatureDisplay className={className} value={value}/>
        : <Typography className={className}>
          {value}
        </Typography>
    }
  </Stack>;

};
export default ThingValue;
