import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  TUnControlColumn,
  useTableData,
  Table
} from '@pizza-hut/hutbot-ui-components';
import React, { ChangeEvent, useMemo, useEffect } from 'react';

//type

import { useLocalization } from 'hooks';
import { TDeviceDataItemUI } from 'types/device';
import { i18NCommonContext } from 'utils/constants';

//style
const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    border: 'none',
    marginTop: theme.spacing(4)
  },
  cell: { padding: theme.spacing(1.5, 0) }
}));


interface IDetailTableProps {
    data: TDeviceDataItemUI[];
    field: string,
    title: string,
}

const DetailTable = ({ data: dataProp, field, title }: IDetailTableProps) => {
  const { translate } = useLocalization();
  const classes = useStyles();
  const columns: TUnControlColumn<TDeviceDataItemUI>[] = useMemo(
    () => [
      { title: translate(`${i18NCommonContext}.time`, 'Time'), field: 'time' },
      {
        title,
        field
      }
      // {
      //     title: 'Status',
      //     field: 'status',
      //     render: (item: TDeviceDataItemUI) => <RenderStatus item={item} />,
      //
      // }
    ],
    [field, title, translate],
  );

  const {
    renderData, updateData, onChangePage, onRowsPerPageChange
  } = useTableData<TDeviceDataItemUI>({
    data: dataProp,
    columns,
    rowsPerPage: 20,
    showAll: false,
    showPagination: true,
  });

  const {
    data, count, rowsPerPage, currentPage
  } = renderData;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChangePage(newPage);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    updateData(dataProp);
    onChangePage(0);
  }, [dataProp, onChangePage, updateData]);

  return (
    <Table
      tableClasses={{ root: classes.tableContainer }}
      cellClasses={{ head: classes.cell, body: classes.cell }}
      columns={columns}
      data={data}
      emptyDataMessage={translate(`${i18NCommonContext}.noData`, 'No Data')}
      currentPage={currentPage}
      count={count}
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={ handleRowsPerPageChange }
      paginationProps={{ labelRowsPerPage: translate(`${i18NCommonContext}.rowPerPage`, 'Rows per page:'), }}
    />
  );
};
export default DetailTable;
