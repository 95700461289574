export const colors = {
  // primary
  phRed: '#E52A33',
  darkRed: '#AB0009',
  mediumRed: '#FF5D65',
  lightRed: '#FBD0D2',
  trRed: '#FAD4D4',
  background: '#F7F4F4',
  background50: '#F7F4F450',
  white: '#FFFFFF',
  darkBlack: '#000000',
  black: '#262222',
  grey50: '#E6E1E1',
  grey100: '#B3ABAB',
  grey200: '#979797',
  grey300: '#807878',
  grey400: '#4D4646',
  grey500: '#323232',
  inactive: '#736C6C',
  // secondary
  light: 'rgba(0, 0, 0, 0.36)',
  main: 'rgba(0, 0, 0, 0.87)',
  dark: 'rgba(0, 0, 0, 0.6)',
  //border
  borderMain: 'rgba(0, 0, 0, 0.1)',
  // other
  green: '#339137',
  lightGreen: '#E6F9E7',
  gold: '#E6A117',
  //warning
  warningMain: '#E5A117',
  warningLight: '#FEEFD1',
  transparent: 'transparent',
};
