import { useTheme } from '@mui/material';
import { memo } from 'react';

import { useLocalization } from 'hooks';
import { TDeviceDataItemUI } from 'types/device';
import { i18NCommonContext } from 'utils/constants';

import { AreaChart } from '../AreaChart/AreaChart';


export type TDoorSensorDisplayProps = {
    chartData: TDeviceDataItemUI[];
    minValue?: number,
    maxValue?: number,
}

const DoorSensorDisplayV2 = memo(({ chartData: data, minValue, maxValue }: TDoorSensorDisplayProps) => {
  const { translate } = useLocalization();
  const theme = useTheme();
  const chartData = (data)?.map((item) => ({
    ...item,
    open: item.value === 'Open' ? 1 : 0,
    close: item.value === 'Close' ? 1 : 0,
  }));

  return <AreaChart data={chartData} tooltipDataKey="value" dataKey={['open', 'close']}
    type={'stepAfter'}
    dataFill={{ close: theme.palette.primary.main }}
    domainPadding={0}
    minValue={minValue}
    maxValue={maxValue}
    title={translate(`${i18NCommonContext}.doorStatus`, 'Door Status')}/>;
});
export default DoorSensorDisplayV2;
