import React, { memo } from 'react';

import { EUnit, withTemperatureUnit } from 'hooks';
import { TDeviceDataItemUI } from 'types/device';
import { EMeasureUnit } from 'utils/constants';

import DetailTable from './DetailTable';


export type TDetailTemperatureTableProps = {
    chartData: TDeviceDataItemUI[];
    temperatureUnit?: EUnit,
    internal?: boolean
}

const DetailTemperatureTable = memo(({ chartData, temperatureUnit }: TDetailTemperatureTableProps) => {
  const isCelsius = temperatureUnit === EUnit.Celsius;
  const dataKey = isCelsius ? 'value': 'valueF';

  return <DetailTable
    data={chartData}
    field={dataKey}
    title={isCelsius ? EMeasureUnit.TempC: EMeasureUnit.TempF}
  />;
});

export default withTemperatureUnit(DetailTemperatureTable);
