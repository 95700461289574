import { ApolloProvider, useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { TAuthenticatedUser } from '@pizza-hut/hutbot-mapp-sdk-js';
import {
  useEffect, useMemo, useState, useCallback
} from 'react';
import TagManager from 'react-gtm-module';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { microAppClient } from 'clients/mapp';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { Dashboard } from 'features/Dashboard/Dashboard';
import Forbidden from 'features/Forbidden/Forbidden';
import { FIND_ALL_STORES } from 'graphql/queries';
import { TFindAllStoresResponse } from 'graphql/types';
import {
  LocalizationProvider,
  TemperatureUnitProvider,
  useMAppAuth,
} from 'hooks';
import { useApolloClient } from 'hooks/useApolloClient';
import { DataExport } from 'pages/data-export/data-export';
import { hutbotTheme } from 'styles/hutbotTheme';
import { TStoreExtended } from 'types/store';

import { useStyles } from './App.styles';
import { ROUTES } from './constants';


const AppContent = () => {
  const classes = useStyles();
  const {
    accessToken,
    user,
    signOut,
    isLoading: isAuthenticating,
    error,
    currentStore,
  } = useMAppAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { loading: storeLoading, data: dataStoreResponse } =
    useQuery<TFindAllStoresResponse>(FIND_ALL_STORES, { skip: !accessToken, });

  useEffect(() => {
    setIsLoading(true);
    if (error) {
      return microAppClient.authenticate();
    }
    setIsLoading(false);
  }, [error]);

  useEffect(() => {
    if (user) {
      TagManager.dataLayer({ dataLayer: { yumId: user.yumId, }, });
    }
  }, [user]);
  const stores: TStoreExtended[] = useMemo(() => {
    return dataStoreResponse?.findAllStoresByUser?.items || [];
  }, [dataStoreResponse]);

  const [selectedStore, setSelectedStore] = useState('');
  useEffect(() => {
    setSelectedStore(currentStore?.champsNumber || stores[0]?.champsNumber);
  }, [stores, currentStore]);

  const onSelectStore = useCallback((selectedStore) => {
    setSelectedStore(selectedStore);
  }, []);

  if (error) {
    return <Alert>{(error as Error)?.message}</Alert>;
  }
  if (isLoading || isAuthenticating || storeLoading) {
    return <LoadingOverlay />;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Switch>
        <Route path={`${ROUTES.DASHBOARD}/:champsNumber?/:deviceId?`}>
          <Dashboard
            stores={stores}
            user={user as unknown as TAuthenticatedUser}
            signOut={signOut}
            defaultChampsNumber={selectedStore}
            onSelectStore={onSelectStore}
          />
        </Route>
        <Route path={`${ROUTES.EXPORT}`}>
          <DataExport
            stores={stores}
            defaultChampsNumber={selectedStore}
            signOut={signOut}
          />
        </Route>
        <Route path="/">
          <Redirect to={ROUTES.DASHBOARD} />
        </Route>
      </Switch>
    </Container>
  );
};

function App() {
  const apolloClient = useApolloClient();
  if (apolloClient) {
    return (
      <ApolloProvider client={apolloClient}>
        <StyledEngineProvider injectFirst={true}>
          <ThemeProvider theme={hutbotTheme}>
            <LocalizationProvider>
              <TemperatureUnitProvider>
                <Router>
                  <Switch>
                    <Route path="/forbidden">
                      <Forbidden />
                    </Route>
                    <AppContent />
                  </Switch>
                </Router>
              </TemperatureUnitProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    );
  }

  return <LoadingOverlay />;
}

export default App;
