import { IEquipmentValue } from 'types/threshold';

import { EDetailTab } from '../constants';

import { EQUIPMENT_TYPE, LOCATION_TYPE } from './constant';


export function extractEquipmentValuesFromDeviceData(location: string): IEquipmentValue[] {
  const equipmentValues = [] as IEquipmentValue[];

  if (!location) {
    return equipmentValues;
  }

  if (location.startsWith(LOCATION_TYPE.SINK)) {
    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.HOT_WATER_AT_SINK,
      detailTabType: EDetailTab.Temperature,
    });
    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.HAND_WASHING_TIME,
      detailTabType: EDetailTab.PeopleSense,
    });

    return equipmentValues;
  }

  if (location.startsWith(LOCATION_TYPE.FREEZER)) {
    equipmentValues.push({
      equipmentType: LOCATION_TYPE.FREEZER,
      detailTabType: EDetailTab.Temperature,
    });

    return equipmentValues;
  }

  if (location.startsWith(LOCATION_TYPE.FRIDGE)) {
    equipmentValues.push({
      equipmentType: LOCATION_TYPE.FRIDGE,
      detailTabType: EDetailTab.Temperature,
    });

    return equipmentValues;
  }

  if (location.startsWith(LOCATION_TYPE.DOOR)) {
    equipmentValues.push({
      equipmentType: LOCATION_TYPE.DOOR,
      detailTabType: EDetailTab.DoorStatus,
    });

    return equipmentValues;
  }

  if (location === LOCATION_TYPE.MANAGER_DESK) {
    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.CO2,
      detailTabType: EDetailTab.CO2,
    });

    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.HUMIDITY,
      detailTabType: EDetailTab.Humidity,
    });

    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.ROOM_TEMP,
      detailTabType: EDetailTab.Temperature,
    });

    return equipmentValues;
  }

  if (location.startsWith(LOCATION_TYPE.PROOFER)) {
    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.PROOFER,
      detailTabType: EDetailTab.Temperature,
    });

    return equipmentValues;
  }

  if (location.startsWith(LOCATION_TYPE.OVEN_DECK)) {
    equipmentValues.push({
      equipmentType: EQUIPMENT_TYPE.OVEN_DECK,
      detailTabType: EDetailTab.Temperature,
    });

    return equipmentValues;
  }

  equipmentValues.push({
    equipmentType: location,
    detailTabType: EDetailTab.Temperature,
  });

  return equipmentValues;
}
