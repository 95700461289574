import Typography from '@mui/material/Typography';

import { EUnit, withTemperatureUnit } from 'hooks';
import { EMeasureUnit } from 'utils/constants';
import { convertToFahrenheit } from 'utils/formatTemperature';


type TTemperatureDisplayProps = {
  value: string,
  className?: string;
  temperatureUnit?: EUnit
}
function TemperatureDisplay({ className, value, temperatureUnit }: TTemperatureDisplayProps) {
  return (
    <Typography className={className}>
      {
        temperatureUnit === EUnit.Celsius ? `${value } ${EMeasureUnit.TempC}`:
          `${convertToFahrenheit(value) } ${EMeasureUnit.TempF}`
      }
    </Typography>
  );
}
export default withTemperatureUnit(TemperatureDisplay);
