import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme: Theme) => {
  return {
    title: {
      overflow: 'hidden',
      flex: 1,
      '&>div': { maxWidth: '100%' }
    },
    appBar: {
      backgroundColor: theme.palette.common.white,
      zIndex: theme.zIndex.drawer + 1,
    },
    header: {},
    headerSelect: {
      maxWidth: '100%',
      textAlign: 'center',
      borderColor: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      '&:before': { borderBottom: 'none !important', },
      '&:after': { borderBottom: 'none !important', },
    },
    drawer: {
      width: '100%',
      [theme.breakpoints.up('sm')]: { display: 'none', },
    },
    mobileBackButton: {
      padding: theme.spacing(1.5, 0),
      // [theme.breakpoints.only('sm')]: {
      //     marginTop: theme.spacing(0.625),
      // },
    },
    drawerMenuItem: {
      padding: theme.spacing(2, 3),
      color: theme.palette.secondary.main
    },
    activeItem: { color: theme.palette.primary.main },
    drawerMenuDivider: { margin: theme.spacing(0, 3, 0, 5) },
    drawerMenuIcon: {
      minWidth: theme.spacing(1),
      padding: theme.spacing(0, 2)
    },
    headerLink: {
      textDecoration: 'none',
      color: theme.palette.secondary.dark
    },
    headerMobileLink: {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      flex: 1,
    },
    select: {
      textAlign: 'center',
      borderColor: theme.palette.getContrastText(theme.palette.primary.light),
      width: '100%',
      '& > .MuiSelect-root': {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      '& > .MuiInput-input': { textAlign: 'left', },
      '& > .MuiSelect-select': { padding: 0, },
      '&:before': { borderBottom: 'none !important', },
    },
  };
});
export default useStyles;
