import React from 'react';

import { WidgetV2 } from '../../components/Widget/Widget-v2';
import { TFindAllDevicesByChampNumbersResponse, } from '../../graphql/types';
import { TLatestDeviceDataUI } from '../../types/device';
import { EHumanReadableDeviceThingTypeNames } from '../../utils/constants';


export type TVisualizeIoTDataProps =
    Omit<TFindAllDevicesByChampNumbersResponse['findAllDevicesByChampNumbers']['items'][number], 'attributes' | 'latestDeviceData' >
    & {
    champsNumber: string;
    deviceId: string;
    location: string;
    isActive: boolean;
    latestDeviceData: TLatestDeviceDataUI | null
};

const VisualizeIoTDataV2 = ({
  thingTypeName,
  champsNumber,
  deviceId,
  location,
  latestDeviceData,
  isActive,
}: TVisualizeIoTDataProps) => {
  return (
    <WidgetV2
      isActive={isActive}
      title={location}
      champsNumber={champsNumber}
      subtitle={`${deviceId} ${EHumanReadableDeviceThingTypeNames[thingTypeName] || thingTypeName}`}
      latestDeviceData={latestDeviceData}
    />
  );
};
export default VisualizeIoTDataV2;
