import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { DEFAULT_BRAND_HEADER } from '@pizza-hut/hutbot-mapp-sdk-js';
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';

import { AUTH_TOKEN_KEY } from '../utils/constants';


export type THutbotGraphQLClientConfig = {
  url: string;
  region: string;
  auth: {
    type: AUTH_TYPE.AWS_LAMBDA;
    token: () => string;
  };
  brand: string;
};

export function createClient(
  config: THutbotGraphQLClientConfig,
  links: ApolloLink[] = []
): ApolloClient<NormalizedCacheObject> {
  const url = config.url;
  const region = config.region;
  const brand = config.brand;

  const auth = {
    type: config.auth.type,
    token: config.auth.token,
  };

  const link = ApolloLink.from([
    onError(({ networkError, graphQLErrors }) => {
      if (
        networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401
      ) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        window.location.href = '/';
      }
      if (graphQLErrors) {
        if (graphQLErrors[0].message === 'Forbidden') {
          window.location.href = '/forbidden';
        }
        // eslint-disable-next-line no-console
        console.error(graphQLErrors[0].message);
      }
    }),
    createAuthLink({ url, region, auth }) as unknown as ApolloLink,
    ...links,
    createHttpLink({ uri: url, headers: { [DEFAULT_BRAND_HEADER]: brand } }),
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
