import {
  createContext, useContext, useState, ComponentType
} from 'react';


export enum EUnit {
  Celsius = 'Celsius',
  Fahrenheit = 'Fahrenheit',
}
const TemperatureUnitContext = createContext({
  unit: '',
  // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
  handleChange: (unit: EUnit) => {},
});

interface ITemperatureUnitProps {
  unit?: EUnit,
  children: any,
}

export const TemperatureUnitProvider =({ unit: propUnit, children }: ITemperatureUnitProps) => {
  const [unit, setUnit] = useState<EUnit>(propUnit || EUnit.Celsius);

  const handleChange = (unit: EUnit) => {
    setUnit(unit);
  };

  return <TemperatureUnitContext.Provider
    value={{ unit, handleChange }}
  >
    {children}
  </TemperatureUnitContext.Provider>;
};

export const useTemperatureUnit = () => useContext(TemperatureUnitContext);

export function withTemperatureUnit<T>(Component: ComponentType<T>) {
  return (props: T) => {
    const { unit } = useTemperatureUnit();

    return <Component temperatureUnit={unit} {...props}/>;
  };
}
