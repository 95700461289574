import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Moment } from 'moment';

import { FULL_DATETIME_FORMAT, SHORT_DATETIME_FORMAT } from './constants';


/**
 *
 * @param timestamp Must be epoch time
 * @returns
 */

export function formatChartTime(timestamp: number): string {
  return format(timestamp * 1000, SHORT_DATETIME_FORMAT);
}

type TChartTimeDetail = {
    time: string,
    fullTime: string,
    label: string,
    tooltip: string
}
export function formatChartTimeDetail(timestamp: number): TChartTimeDetail {
  return {
    time: format(timestamp * 1000, SHORT_DATETIME_FORMAT),
    fullTime: format(timestamp * 1000, FULL_DATETIME_FORMAT),
    label: format(timestamp * 1000, 'HH:mm'),
    tooltip: format(timestamp * 1000, 'HH:mm, dd/MM ')
  };
}

export function formatTimeStamp(timestamp: number, timezone: string, format = FULL_DATETIME_FORMAT): string {
  return formatInTimeZone(timestamp * 1000, timezone, format);
}

type TUnitOfTime = 'd' | 'w' | 'm'
export function getStartUnix(date: Moment, unitOfTime: TUnitOfTime = 'd'): number {
  return date.startOf(unitOfTime).unix();
}
export function getEndUnix(date: Moment, unitOfTime: TUnitOfTime = 'd'): number {
  return date.endOf(unitOfTime).unix();
}
