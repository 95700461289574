import {
  KeyboardEvent, MouseEvent, useCallback, useState
} from 'react';


type TUseDrawerResponse = {
  drawerOpen?: boolean,
  setDrawerOpen: (value: boolean) => void
  toggleDrawer: (open: boolean) => (event: KeyboardEvent | MouseEvent) => void
}

type TUserDrawerProps = {
  open?: boolean
}
const useDrawer = ({ open }: TUserDrawerProps): TUseDrawerResponse => {
  const [drawerOpen, setDrawerOpen] = useState(open);
  const toggleDrawer =
        useCallback((open: boolean) =>
          (event: KeyboardEvent | MouseEvent): void => {
            if (
              event &&
                    event.type === 'keydown' &&
                    ((event as KeyboardEvent).key === 'Tab' ||
                        (event as KeyboardEvent).key === 'Shift')
            ) {
              return;
            }
            setDrawerOpen(open);

          }, []);

  return {
    drawerOpen,
    setDrawerOpen,
    toggleDrawer,
  };
};
export default useDrawer;
