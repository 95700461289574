import { MicroAppApiClient, MicroAppClient } from '@pizza-hut/hutbot-mapp-sdk-js';


const mappId = process.env.REACT_APP_MAPP_ID as string;
export const microAppClient = new MicroAppClient({
  mappId,
  authURL: process.env.REACT_APP_MAPP_AUTH_URL,
});
export const microAppApiClient = new MicroAppApiClient({
  mappId,
  baseURL: process.env.REACT_APP_MAPP_BASE_URL,
});
