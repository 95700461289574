import WarningIcon from '@mui/icons-material/Warning';
import {
  Card, CardContent, CardHeader, Divider, Grid, Tooltip, Typography
} from '@mui/material';
import clsx from 'clsx';
import { format } from 'date-fns';
import { PropsWithChildren } from 'react';

import { useLocalization } from 'hooks';
import { FULL_DATETIME_FORMAT, i18NCommonContext } from 'utils/constants';

import { TLatestDeviceDataUI } from '../../types/device';
import ThingValue from '../ThingValue/ThingValue';

import useStyles from './Widget.style';


export type TWidgetProps = PropsWithChildren<{
    isLoading?: boolean;
    isActive:boolean,
    error?: string;
    title: string;
    subtitle: string;
    champsNumber: string;
    latestDeviceData: TLatestDeviceDataUI | null
}>;

export function WidgetV2({
  error,
  isActive,
  title,
  subtitle,
  latestDeviceData,
}: TWidgetProps) {
  const { translate } = useLocalization();
  const classes = useStyles();

  if (error) {
    return <pre>{error}</pre>;
  }

  const { value, timestamp } = latestDeviceData || {};

  const isOlderThan24Hours = timestamp && (timestamp * 1000) < (Date.now() - (24 * 60 * 60 * 1000));
  const warning = isOlderThan24Hours && translate(`${i18NCommonContext}.noDataIn24h`, 'No data in the last 24 Hours');

  return (
    <Card className={clsx(classes.widgetContainer, { [classes.active]: isActive })}>
      <CardHeader
        sx={{ p: 2 }}
        title={title}
        classes={{
          title: classes.widgetTitle,
          subheader: classes.widgetSubtitle,
        }}
        subheader={subtitle}
        action={warning && (
          <Tooltip title={warning} color="warning">
            <WarningIcon />
          </Tooltip>
        )}
      />
      <Divider/>
      <CardContent className={classes.widgetContent}>
        {value && <Grid container className={classes.content}>
          {
            value?.map(({ icon, value, title }, index) => {
              return <Grid item xs={6} className={classes.item} key={index}>
                <ThingValue className={classes.subtitle} icon={icon} value={value} />
                <Typography className={classes.subtitle2} variant={'subtitle2'}>
                  {title}
                </Typography>
              </Grid>;
            })
          }
          {/*<Grid item xs={6} className={classes.item}>*/}
          {/*    <Stack direction={'row'} justifyContent={'flex-end'}>*/}
          {/*        <Chip label={'In range'} type={'success'}/>*/}
          {/*        <Chip label={'Out of range'} type={'error'}/>*/}
          {/*    </Stack>*/}
          {/*</Grid>*/}
        </Grid>}
        {timestamp && <Typography className={classes.bottom}>
          {translate(`${i18NCommonContext}.lastUpdated`, 'Last updated:')} {format(timestamp * 1000, FULL_DATETIME_FORMAT)}
        </Typography>
        }
        {
          !latestDeviceData && <Typography className={classes.bottom}>
            {translate(`${i18NCommonContext}.noData`, 'No Data')}
          </Typography>
        }
      </CardContent>
    </Card>
  );
}
