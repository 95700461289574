import { LOCATION_TYPE } from './constant';


export function standardizeLocationType(location: string): string {
  const locationType = Object.keys(LOCATION_TYPE).find(
    type => location.startsWith(type) && type !== LOCATION_TYPE.OVEN_DECK
  );

  return locationType || location;
}
