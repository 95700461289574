import { get, find } from 'lodash';
import {
  createContext, useContext, useState, useEffect, useCallback, useMemo
} from 'react';

import { TLocale, TLocalization, TLocalizationContext } from 'types/localization';
import {
  LOCALIZATION_DEFAULT_LOCALE,
  LOCALIZATION_DEFAULT_VERSION,
  LOCALIZATION_STORAGE_KEY
} from 'utils/constants';


const LocalizationContext = createContext<TLocalizationContext>({
  localization: {},
  currentLocale: LOCALIZATION_DEFAULT_LOCALE,
  version: LOCALIZATION_DEFAULT_VERSION,
  locales: [],
  translate: () => '',
  handleChange: () => {
  },
});

interface ILocalizationProps {
  children: any
}

const localLocalization = localStorage.getItem(LOCALIZATION_STORAGE_KEY);

let localizationStorage: TLocalization = {
  current: LOCALIZATION_DEFAULT_LOCALE,
  version: { [LOCALIZATION_DEFAULT_LOCALE]: LOCALIZATION_DEFAULT_VERSION },
};

try {
  if (localLocalization) {
    localizationStorage = JSON.parse(localLocalization);
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('init localization storage failed', e);
}
export const LocalizationProvider =({ children }: ILocalizationProps) => {

  const [locales, setLocales] = useState<TLocale[]>([]);

  const [localization, setLocalization] = useState<TLocalization>(localizationStorage);


  const verifyLocalization = useCallback(async (locales: TLocale[], version, current) => {
    const newestVersion = find(locales, locale => locale.locale === current)?.version || -1;
    const currentLocalizationVersion = version[current];
    if (!currentLocalizationVersion || currentLocalizationVersion < newestVersion) {
      const localization = await import(`locales/${current}.json`);
      setLocalization((prev: TLocalization) => {
        const newConfig = {
          ...prev,
          current,
          version: {
            ...prev.version,
            [current]: newestVersion
          },
          [current]: localization
        };
        localStorage.setItem(LOCALIZATION_STORAGE_KEY, JSON.stringify(newConfig));

        return newConfig;
      });
    } else {
      setLocalization((prev: TLocalization) => {
        const newConfig = {
          ...prev,
          current,
        };
        localStorage.setItem(LOCALIZATION_STORAGE_KEY, JSON.stringify(newConfig));

        return newConfig;
      });
    }
  }, [setLocalization]);

  useEffect(() => {
    const checkLocalization = async () => {
      try {
        const {
          current = LOCALIZATION_DEFAULT_LOCALE,
          version = { [LOCALIZATION_DEFAULT_LOCALE]: LOCALIZATION_DEFAULT_VERSION },
        } = localizationStorage;
        const locales = await import('locales/list.json');
        setLocales(locales);

        return verifyLocalization(locales, version, current);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('init localization failed', e);
      }
    };
    checkLocalization();
  }, [setLocales, setLocalization, verifyLocalization]);

  const handleChange = useCallback((locale:TLocale) => {
    const { version = { [LOCALIZATION_DEFAULT_LOCALE]: LOCALIZATION_DEFAULT_VERSION }, } = localization;

    return verifyLocalization(locales, version, locale.locale);
  }, [locales, localization, verifyLocalization]);


  const {
    current,
    version,
  } = localization;

  const localizationObject = useMemo(() => localization[current] || {}, [localization, current]);

  const translate = useCallback((key: string, defaultLabel: string) => {
    return get(localizationObject, key, defaultLabel);
  }, [localizationObject]);

  return <LocalizationContext.Provider
    value={{
      locales,
      currentLocale: current,
      version: version?.[current] || -1,
      localization: localizationObject,
      handleChange,
      translate
    }}
  >
    {children}
  </LocalizationContext.Provider>;
};

export const useLocalization = () => useContext(LocalizationContext);
