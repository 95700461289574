import { useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';


const useScreenSize = (): {isMobileUI: boolean} => {
  const theme = useTheme();
  const [isMobileUI, setMobileUI] = useState<boolean>(false);

  const handleWindowWidthChange = useCallback(() => {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;
    setMobileUI(isSmallScreen);
  }, [theme.breakpoints.values.md, setMobileUI]);
  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();

    return function cleanup(): void {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  }, [handleWindowWidthChange]);

  return { isMobileUI };
};
export default useScreenSize;
