import { useQuery } from '@apollo/client';
import round from 'lodash/round';
import { createContext, useContext, useMemo } from 'react';

import { FIND_ALL_THRESHOLD } from 'graphql/queries';
import { TFindAllThresholdResponse } from 'graphql/types';
import { TThreshold, TThresholdContext } from 'types/threshold';


const ThresholdContext = createContext<TThresholdContext>({
  loading: false,
  threshold: [] as TThreshold[],
});

interface IThresholdProps {
    children: any
}

export const ThresholdProvider = ({ children }: IThresholdProps) => {
  const { loading: thresholdLoading, data: dataThreshold } = useQuery<TFindAllThresholdResponse>(FIND_ALL_THRESHOLD);

  const threshold: TThreshold[] = useMemo(() => {
    if (dataThreshold?.findAllThresholds?.items?.length) {
      return dataThreshold?.findAllThresholds?.items.map((threshold) => {
        return {
          ...threshold,
          minValueUI: threshold.minValue && round(threshold.minValue, 2),
          maxValueUI: threshold.maxValue && round(threshold.maxValue, 2),
        };
      });
    }

    return [];
  }, [dataThreshold]);

  return <ThresholdContext.Provider
    value={{
      loading: thresholdLoading,
      threshold,
    }}
  >
    {children}
  </ThresholdContext.Provider>;
};

export const useThreshold = () => useContext(ThresholdContext);
