import {
  DEFAULT_MAPP_TOKEN_QUERY_PARAM,
  IGetUserProfileResponse,
  IUserCurrentStore,
} from '@pizza-hut/hutbot-mapp-sdk-js';
import {
  useCallback, useEffect, useRef, useState
} from 'react';
import useLocalStorage from 'use-local-storage';

import { microAppApiClient, microAppClient } from 'clients/mapp';
import { AUTH_TOKEN_KEY, BRAND_KEY } from 'utils/constants';
import { getQueryString } from 'utils/utils';


type TUseMappAuthResponse = {
    user: IGetUserProfileResponse | null,
    signOut: () => void,
    accessToken: string | null,
    brand: string | undefined,
    isLoading: boolean,
    error: Error | unknown,
    currentStore: IUserCurrentStore | undefined,
}

const mappToken = getQueryString(DEFAULT_MAPP_TOKEN_QUERY_PARAM);

export const useMAppAuth = (): TUseMappAuthResponse => {
  const [user, setUser] = useState<IGetUserProfileResponse | null>(null);
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(
    AUTH_TOKEN_KEY,
    null,
    {
      serializer: (token) => String(token),
      parser: (token) => (token === 'null' ? null : token),
    }
  );
  const [brand, setPrimaryBrand] = useLocalStorage<string | undefined>(
    BRAND_KEY,
    undefined,
    {
      serializer: (brand) => String(brand),
      parser: (brand) => (brand === 'null' ? undefined : brand),
    }
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | unknown>(undefined);
  const [isClaimingTokenOnDesktop, setIsClaimingTokenOnDesktop] =
    useState<boolean>(Boolean(mappToken));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const claimingTokenTimeout = useRef<ReturnType<typeof setTimeout>>();

  const subscribeForAccessToken = useCallback(() => {
    return microAppClient.subscribe({
      onReceivedRedeemableToken: async ({ mappToken, brand }) => {
        try {
          if (claimingTokenTimeout.current) {
            clearTimeout(claimingTokenTimeout.current);
          }
          const token = await microAppApiClient.redeemToken(mappToken);
          setAccessToken(token.access_token);
          setPrimaryBrand(brand);
          const userProfile = await microAppApiClient.getUserProfile(
            token.access_token
          );
          setUser(userProfile);
          setIsLoading(false);
          setIsClaimingTokenOnDesktop(false);

          return token;
        } catch (e) {
          setError(e);
        }
      },
      onError: setError,
    });
  }, [setAccessToken, setPrimaryBrand]);

  useEffect(() => {
    if (!accessToken && microAppClient.isMobileMicroApp) {
      microAppClient.authenticate();

      return subscribeForAccessToken();
    }
    if (accessToken && microAppClient.isMobileMicroApp) {
      return;
    }
    if (
      !accessToken &&
      microAppClient.isBrowserMicroApp &&
      !isClaimingTokenOnDesktop
    ) {
      setIsClaimingTokenOnDesktop(true);

      return microAppClient.authenticate();
    }

    if (claimingTokenTimeout.current) {
      clearTimeout(claimingTokenTimeout.current);
    }
    setIsClaimingTokenOnDesktop(true);
    claimingTokenTimeout.current = setTimeout(() => {
      if (accessToken) {
        return;
      }
      setIsClaimingTokenOnDesktop(false);
    }, 60000);

    return subscribeForAccessToken();
  }, [
    setAccessToken,
    accessToken,
    isLoading,
    isClaimingTokenOnDesktop,
    subscribeForAccessToken,
  ]);

  //Handle token verification
  useEffect(() => {
    if (!accessToken || isAuthenticated) {
      return;
    }
    (async function verify(): Promise<void> {
      try {
        const isTokenValid = await microAppApiClient.verifyJwtToken(
          accessToken
        );
        if (isTokenValid) {
          if (!user) {
            setIsAuthenticated(true);
            const userProfile = await microAppApiClient.getUserProfile(
              accessToken
            );
            setUser(userProfile);
          }

          return;
        }

        setUser(null);
        setAccessToken(null);
        setError(new Error('The access token is invalid.'));
      } catch (e) {
        setError(new Error('The access token is invalid.'));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, user, setAccessToken, isAuthenticated]);

  const signOut = useCallback(() => {
    setUser(null);
    setAccessToken(null);
    microAppClient.authenticate();
  }, [setAccessToken]);

  return {
    user,
    signOut,
    accessToken,
    brand,
    isLoading,
    error,
    currentStore: microAppClient.getUserCurrentStore(),
  };
};
