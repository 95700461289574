import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Divider, IconButton, SelectChangeEvent, Stack, Theme, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Chip, DateRangePicker, Loading, Select, Tabs
} from '@pizza-hut/hutbot-ui-components';
import { round } from 'lodash';
import moment, { Moment } from 'moment';
import React, {
  KeyboardEvent, MouseEvent, useEffect, useMemo, useRef, useState
} from 'react';
import { useReactToPrint } from 'react-to-print';

import { microAppClient } from 'clients/mapp';
import { AreaChart } from 'components/AreaChart/AreaChart';
import { BarChart } from 'components/BarChart/BarChart';
import DetailTable from 'components/DetailTable/DetailTable';
import DetailTemperatureTable from 'components/DetailTable/DetailTemperatureTable';
import DoorSensorDisplayV2, { TDoorSensorDisplayProps } from 'components/DoorSensorDisplayV2/DoorSensorDisplayV2';
import TemperatureChart from 'components/TemperatureChart/TemperatureChart';
import ThingValue from 'components/ThingValue/ThingValue';
import { FIND_ALL_DEVICE_DATA_BY_ID } from 'graphql/queries';
import { TFindAllDeviceDataByIdResponse, } from 'graphql/types';
import { useLocalization, useThreshold } from 'hooks';
import { TFilterDate } from 'types/common';
import {
  TDeviceDataItemUI,
  TLatestDeviceUI,
} from 'types/device';
import { TStoreExtended } from 'types/store';
import { TThreshold } from 'types/threshold';
import {
  DATE_FORMAT,
  EDetailTab,
  EDeviceICon,
  EDeviceThingTypeNames,
  EHumanReadableDeviceThingTypeNames, EMeasureUnit,
  i18NCommonContext,
  isTempC
} from 'utils/constants';
import { formatChartTimeDetail, getEndUnix, getStartUnix } from 'utils/formatChartTime';
import { convertToFahrenheit } from 'utils/formatTemperature';
import { extractEquipmentValuesFromDeviceData } from 'utils/threshold/extractEquipmentValuesFromDeviceData';
import { getEquipmentThresholdByType } from 'utils/threshold/getEquipmentThresholdByType';

import QuickExport from './QuickExport';


const useStyles = makeStyles((theme: Theme) => ({
  detail: { padding: theme.spacing(0, 2) },
  detailContainer: { position: 'relative', },
  title: { fontSize: 20, },
  chip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex'
  },
  headerItemContainer: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
      '&:first-child': { marginLeft: theme.spacing(0) }
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(50% - ${theme.spacing(1)})`,
      marginLeft: theme.spacing(1)
    }
  },
  headerTitle: {
    fontSize: 34,
    lineHeight: 1,
    marginLeft: theme.spacing(1.5)
  },
  headerSubTitle: {
    fontSize: 14,
    marginTop: theme.spacing(1)
  },
  chartArea: {
    height: 500,
    fontSize: 10,
  },
}));

enum ETimeFilterType {
    LastDay = '24h',
    Last30Days = 'Last30Days',
    Last7Days = 'Last7Days',
    Last365Days = 'Last365Days',
    CustomRange = 'customRange',
}

interface IHeaderItem {
    value: string,
    title: string,
    icon?: EDeviceICon,
}

const HeaderItem = ({ value, title, icon }: IHeaderItem) => {
  const classes = useStyles();

  return <div className={classes.headerItemContainer}>
    <Stack direction={'row'} alignItems={'center'}>
      <ThingValue className={classes.headerTitle} icon={icon} value={value}/>
    </Stack>
    <Typography variant={'subtitle2'} className={classes.headerSubTitle}>
      {title}
    </Typography>
  </div>;
};

export type TDashboardProps = {
    champsNumber?: string;
    device: TLatestDeviceUI;
    inDrawer?: boolean;
    store: TStoreExtended,
    handleCloseDrawer?: (event: KeyboardEvent | MouseEvent) => void;
};

type TTabPanelProps = {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function TabPanel(props: TTabPanelProps) {
  const classes = useStyles();
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      style={{ width: '100%' }}
      role='tabpanel'
      hidden={value !== index}
      id={`hut-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box sx={{ display: value === index ? 'block' : 'none' }}>
        <div className={classes.chartArea}>
          {value === index ? children : null}
        </div>
      </Box>
    </div>
  );
}

export default function Detail({
  device, inDrawer = false, handleCloseDrawer, store
}: TDashboardProps) {
  const { translate } = useLocalization();
  const classes = useStyles();
  const {
    thingTypeName,
    thingName,
    attributes,
  } = device;

  const { threshold = [] } = useThreshold();

  const deviceEquipmentThreshold = useMemo(() => {
    const equipmentValues = extractEquipmentValuesFromDeviceData(attributes.Location);
    const deviceEquipmentThreshold: Record<string, TThreshold | undefined> = {};
    equipmentValues.forEach(type => {
      deviceEquipmentThreshold[type.detailTabType] = getEquipmentThresholdByType(threshold, type.equipmentType, attributes.ChampsNumber, attributes.Location);
    });

    return deviceEquipmentThreshold;
  }, [attributes.ChampsNumber, attributes.Location, threshold]);


  const [isAutoRefresh] = React.useState(false);
  const [autoRefreshInterval] = React.useState(10);
  const isPolling = useRef(false);
  const [tabValue, setTabValue] = useState<EDetailTab | null>(null);
  const [timeFilterType, setTimeFilterType] = useState<ETimeFilterType>(ETimeFilterType.LastDay);
  const [dateFilterState, setDateFilterState] = useState<TFilterDate>({
    startDate: getStartUnix(moment().subtract(1, 'd')),
    endDate: getEndUnix(moment()),
  });

  const filterOptions = useMemo(() => ([
    {
      value: ETimeFilterType.LastDay,
      label: translate(`${i18NCommonContext}.date.last24h`, 'Last 24 hours')
    },
    {
      value: ETimeFilterType.Last7Days,
      label: translate(`${i18NCommonContext}.date.last7d`, 'Last 7 days')
    },
    {
      value: ETimeFilterType.Last30Days,
      label: translate(`${i18NCommonContext}.date.last30d`, 'Last 30 days')
    },
    {
      value: ETimeFilterType.Last365Days,
      label: translate(`${i18NCommonContext}.date.last365d`, 'Last 365 days')
    },
    {
      value: ETimeFilterType.CustomRange,
      label: translate(`${i18NCommonContext}.date.customRange`, 'Custom range')
    }
  ]), [translate]);
  const componentRef = useRef(null);
  const print = useReactToPrint({ content: () => componentRef.current, });

  const {
    data,
    startPolling,
    stopPolling,
    loading,
  } = useQuery<TFindAllDeviceDataByIdResponse>(FIND_ALL_DEVICE_DATA_BY_ID, {
    variables: { deviceId: thingName, limit: 10000, dateRange: dateFilterState },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    setTabValue(null);
  }, [thingName]);

  useEffect(() => {
    if (isAutoRefresh) {
      isPolling.current && stopPolling();
      startPolling(autoRefreshInterval * 1000);
      isPolling.current = true;

      return;
    }
    stopPolling();
    isPolling.current = false;
  }, [isAutoRefresh, autoRefreshInterval, stopPolling, startPolling]);

  const chartData: TDeviceDataItemUI[] = useMemo(() => {
    if (!data?.findAllDeviceDataById?.items) {
      return [];
    }
    const sortData = data.findAllDeviceDataById.items.map(e => e).sort((a, b) => a.timestamp - b.timestamp);
    const { data: chartData } = sortData
      .reduce<{data: TDeviceDataItemUI[], preHandwashingCount?: number}>((acc, deviceData) => {
        const { timestamp } = deviceData;
        const timeDetailFormatted = formatChartTimeDetail(timestamp);
        switch (thingTypeName) {
          case EDeviceThingTypeNames.SigFoxPeopleSense: {
            const { preHandwashingCount } = acc;
            const { value: countAccumulator } = deviceData;

            if (!preHandwashingCount || !countAccumulator) {
              return {
                data: acc.data,
                preHandwashingCount: preHandwashingCount || +countAccumulator
              };
            }

            return {
              data: [
                ...acc.data,
                              {
                                ...deviceData,
                                value: preHandwashingCount > +countAccumulator ? +countAccumulator : +countAccumulator - preHandwashingCount,
                                time: timeDetailFormatted.time,
                                label: timeDetailFormatted.label,
                                tooltipSubtitle: timeDetailFormatted.tooltip,
                              } as TDeviceDataItemUI
              ],
              preHandwashingCount: +countAccumulator
            };
          }
          default:
            break;
        }

        return {
          data: [
            ...acc.data,
                        {
                          ...deviceData,
                          value: +deviceData.value ? round(+deviceData.value, 2) : deviceData.value,
                          time: timeDetailFormatted.time,
                          label: timeDetailFormatted.label,
                          tooltipSubtitle: timeDetailFormatted.tooltip,
                          valueF: deviceData.measureUnit && isTempC(deviceData.measureUnit) ? convertToFahrenheit(deviceData.value) : null
                        } as TDeviceDataItemUI
          ]
        };
      }, { data: [] });

    return chartData;
  }, [data?.findAllDeviceDataById.items, thingTypeName]);

  const tabsIndex = useMemo(() => {
    if (!thingTypeName || !device?.latestDeviceDataObject) return [];

    switch (thingTypeName) {
      case EDeviceThingTypeNames.SigFoxOrbitKTempProbe:
      case EDeviceThingTypeNames.SigFoxAyGaWacs:
      case EDeviceThingTypeNames.TestoSensorQsrTemp:
      case EDeviceThingTypeNames.TestoSensorT3Temp:
      case EDeviceThingTypeNames.TestoSensorWirelessTemp:
      case EDeviceThingTypeNames.TestoSensorT2Temp:
      case EDeviceThingTypeNames.SigFoxPipeTemperatureSimpleIndustry: {
        let value = [
          {
            label: translate(`${i18NCommonContext}.temperature`, 'Temperature'),
            value: EDetailTab.Temperature,
          }];

        return value;
      }
      case EDeviceThingTypeNames.SigFoxCO2Sensor: {
        const valueObject = device.latestDeviceDataObject;
        let value = [
          {
            label: 'CO2',
            value: EDetailTab.CO2,
          }];
        if (valueObject.ROOM_TEMP) {
          value.push({
            label: translate(`${i18NCommonContext}.temperature`, 'Temperature'),
            value: EDetailTab.Temperature,
          });
        }
        if (valueObject.HUMIDITY) {
          value.push({
            label: translate(`${i18NCommonContext}.humidity`, 'Humidity'),
            value: EDetailTab.Humidity,
          });
        }

        return value;
      }
      case EDeviceThingTypeNames.TestoSensorQsrDoor:
      case EDeviceThingTypeNames.TestoSensorWirelessDoor:
      case EDeviceThingTypeNames.TestoSensorT2Door:
      case EDeviceThingTypeNames.TestoSensorT3Door:
      case EDeviceThingTypeNames.SigFoxDoorSensorSimplePack: {
        return [{
          label: translate(`${i18NCommonContext}.doorStatus`, 'Door Status'),
          value: EDetailTab.DoorStatus,
        }];
      }
      case EDeviceThingTypeNames.SigFoxPeopleSense: {
        return [{
          label: translate(`${i18NCommonContext}.handwashingCount`, 'Handwashing Count'),
          value: EDetailTab.PeopleSense,
        }];
      }
      default:
        return [];
    }
  }, [device.latestDeviceDataObject, thingTypeName, translate]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue as EDetailTab);
  };

  const handleFilterOptionChanged = (e: SelectChangeEvent<ETimeFilterType>) => {
    const value = e.target.value as ETimeFilterType;
    const now = getEndUnix(moment());
    let numberOfDays = 1;
    switch (value) {
      case ETimeFilterType.LastDay: {
        numberOfDays = 1;
        break;
      }
      case ETimeFilterType.Last7Days: {
        numberOfDays = 7;
        break;
      }
      case ETimeFilterType.Last30Days: {
        numberOfDays = 30;
        break;
      }
      case ETimeFilterType.Last365Days: {
        numberOfDays = 365;
        break;
      }
      default:
        break;
    }
    const startDate = getStartUnix(moment().subtract(numberOfDays, 'd'));
    setDateFilterState({
      startDate,
      endDate: now,
    });
    setTimeFilterType(value as ETimeFilterType);
  };

  const handleDateFilterChanged = (value: { from: Moment | null; to: Moment | null }) => {
    let startDate, endDate;
    if (value.from !== null)
      startDate = getStartUnix(value.from);

    if (value.to !== null)
      endDate = getEndUnix(value.to);

    setDateFilterState({
      startDate,
      endDate,
    });
  };

  const tabActive = tabValue || tabsIndex?.[0]?.value;

  return (
    <div ref={componentRef}>
      <Stack direction={'row'} sx={{ p: 1, mb: 1 }} alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction={'row'} sx={{ overflow: 'hidden' }}>
          <Typography component={'h2'} sx={{ mr: 1 }} className={classes.title}>
            {attributes?.Location || ''}
          </Typography>
          <Chip
            classes={{ root: classes.chip }}
            label={`${attributes.DeviceId} ${EHumanReadableDeviceThingTypeNames[thingTypeName] || thingTypeName}`}/>
        </Stack>
        <Stack direction={'row'} className={'not-print'}>
          {
            !microAppClient.isMobileMicroApp ? <>
              {data?.findAllDeviceDataById.items.length ?
                <QuickExport
                  store={store}
                  data={data}
                  thingType={thingTypeName}
                  fileName={`quick-export-${thingTypeName}-${thingName}.csv`}/> : null
              }
              <IconButton
                aria-label="Print"
                size="medium"
                onClick={print}>
                <PrintIcon fontSize="inherit"/>
              </IconButton>
            </>
              : null
          }
          {
            inDrawer && handleCloseDrawer ? <IconButton onClick={handleCloseDrawer}>
              <CloseIcon/>
            </IconButton> : null
          }
        </Stack>
      </Stack>
      <Divider/>
      {device?.latestDeviceDataObject ?
        <div className={classes.detail}>
          <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
            {
              device.latestDeviceDataObject.value?.map(({ icon, value, title }) => {
                return <HeaderItem
                  value={value}
                  key={value}
                  title={title}
                  icon={icon}
                />;
              })
            }
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ my: 3 }} spacing={2}>
            <Select
              disabled={false}
              sx={{ width: '100%' }}
              value={timeFilterType}
              onChange={handleFilterOptionChanged}
              onClose={() => {
              }}
              options={filterOptions}
            />
            {
              timeFilterType === ETimeFilterType.CustomRange ?
                <Stack direction={'row'}>
                  <DateRangePicker
                    onChange={handleDateFilterChanged}
                    disableFuture
                    value={{
                      from: moment.unix(dateFilterState.startDate as number),
                      to: moment.unix(dateFilterState.endDate as number),
                    }}
                    inputFormat={DATE_FORMAT}
                  />
                  <IconButton disableRipple color="inherit" sx={{ width: 56, mx: 1 }} onClick={() => {
                  }}>
                    <SearchIcon/>
                  </IconButton>
                </Stack>
                : null
            }
          </Stack>
          <Box className={classes.detailContainer}>
            {
              loading ? <Loading text={translate(`${i18NCommonContext}.loading`, 'Loading...')}/> :
                <div>
                  {
                    tabsIndex?.length > 1 ?
                      <Tabs value={tabActive} onChange={handleTabChange} tabs={tabsIndex}
                        sx={{ my: 2 }}
                        tabSx={{ flexGrow: 1, py: 2 }}/> : null
                  }
                  {
                    tabsIndex ? tabsIndex.map(tab => {
                      const threshold = deviceEquipmentThreshold[tab.value];
                      switch (tab.value) {
                        case EDetailTab.PeopleSense:
                          return <TabPanel value={tabActive} key={tab.value}

                            index={tab.value}>
                            <BarChart data={chartData}
                              tooltipSuffix={''}
                              minValue={threshold?.minValue}
                              maxValue={threshold?.maxValue}
                              dataKey={['value']}
                              domainPadding={0}
                              title={translate(`${i18NCommonContext}.title.handwashing`, `Handwashing (${EMeasureUnit.Times})`)}/>
                            {chartData?.length ?
                              <DetailTable data={chartData} field={'value'}
                                title={translate(`${i18NCommonContext}.title.handwashing`, `Handwashing (${EMeasureUnit.Times})`)}/> : null}
                          </TabPanel>;
                        case EDetailTab.CO2:
                        {
                          const co2chartData = chartData.filter(e => e.equipmentType === 'CO2');

                          return <TabPanel value={tabActive} key={tab.value}
                            index={tab.value}>
                            <AreaChart data={co2chartData}
                              tooltipSuffix={EMeasureUnit.PPM}
                              minValue={threshold?.minValue}
                              maxValue={threshold?.maxValue}
                              dataKey={['value', 'baselineValue']}
                              title={`CO2 (${EMeasureUnit.PPM})`}
                            />
                            {co2chartData?.length ? <DetailTable data={co2chartData} field={'value'}
                              title={translate(`${i18NCommonContext}.title.co2`, `CO2 (${EMeasureUnit.PPM})`)}/> : null}
                          </TabPanel>;
                        }
                        case EDetailTab.Temperature:
                        { const temperatureChartData = chartData.filter(e => e.measureUnit && isTempC(e.measureUnit));

                          return <TabPanel value={tabActive} key={tab.value}
                            index={tab.value}>
                            <TemperatureChart
                              threshold={threshold}
                              chartData={temperatureChartData}/>
                            {temperatureChartData?.length ? <DetailTemperatureTable
                              chartData={temperatureChartData}/> : null}
                          </TabPanel>;}
                        case EDetailTab.Humidity:
                        { const humiditychartData = chartData.filter(e => e.equipmentType === 'HUMIDITY');

                          return <TabPanel value={tabActive} key={tab.value}
                            index={tab.value}>
                            <BarChart data={humiditychartData} tooltipSuffix={EMeasureUnit.Percent}
                              dataKey={['value']}
                              minValue={threshold?.minValue}
                              maxValue={threshold?.maxValue}
                              title={translate(`${i18NCommonContext}.title.humidity`, `Humidity (${EMeasureUnit.Percent})`)}/>
                            {humiditychartData?.length ?
                              <DetailTable data={humiditychartData} field={'value'}
                                title={translate(`${i18NCommonContext}.title.humidity`, `Humidity (${EMeasureUnit.Percent})`)}/> : null}
                          </TabPanel>;}
                        case EDetailTab.DoorStatus:
                          return <TabPanel value={tabActive} key={tab.value}
                            index={tab.value}>
                            <DoorSensorDisplayV2
                              minValue={threshold?.minValue}
                              maxValue={threshold?.maxValue}
                              chartData={chartData as TDoorSensorDisplayProps['chartData']}/>
                            {chartData?.length ?
                              <DetailTable data={chartData} field={'value'}
                                title={translate(`${i18NCommonContext}.value`, 'Value')}/> : null}
                          </TabPanel>;
                        default:
                          return null;
                      }
                    }) : null
                  }
                </div>
            }
          </Box>
        </div> : <Typography sx={{ p: 2 }}>
          {translate(`${i18NCommonContext}.noData`, 'No Data')}
        </Typography>
      }
    </div>
  );
}
