import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useEffect, useState } from 'react';

import { createClient } from 'graphql/createClient';
import { createApolloClientConfig } from 'graphql/createClientConfig';

import { useMAppAuth } from './useMAppAuth';


export const useApolloClient = (): ApolloClient<NormalizedCacheObject> | undefined => {
  const {
    isLoading, accessToken, brand, error
  } = useMAppAuth();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    if (!isLoading && !error && accessToken && brand) {
      const apolloClientConfig = createApolloClientConfig(accessToken, brand);
      const apolloClient = createClient(apolloClientConfig);
      setClient(apolloClient);
    }
  }, [isLoading, error, accessToken, brand]);

  return client;
};
