import { useMemo } from 'react';
import { CSVLink } from 'react-csv';

import { withTemperatureUnit } from 'hooks/useTemperatureUnit';
import { IDataExportDocProps } from 'types/common';
import { exportCSV } from 'utils/export-csv';

import useStyles from './data-export.styles';


function DataExportDoc({
  data: dataProps,
  temperatureUnit,
  fileName,
  thingType,
  store,
}: IDataExportDocProps) {
  const classes = useStyles();
  const dataExport = useMemo(() => {
    return exportCSV({
      data: dataProps,
      store,
      thingType,
      temperatureUnit,
    });
  }, [dataProps, store, thingType, temperatureUnit]);

  const { headers, data } = dataExport;

  return (
    <CSVLink
      data={data}
      filename={fileName}
      headers={headers}
      className={classes.download}
      target="_blank"
    >
            Download
    </CSVLink>
  );
}
export default withTemperatureUnit(DataExportDoc);
