import { Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  Bar,
  BarChart as LibBarChart,
  Brush,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import ChartTooltip from '../ChartTooltip';


export type TTemperatureChartProps = {
    title?: string,
    data: any;
    tooltipSuffix: string,
    dataKey: string[];
    tooltipDataKey?: string,
    dataFill?: Record<string, string>;
    showBrush?: boolean,
    domainPadding?: number,
    minValue?: number,
    maxValue?: number,
};

export function BarChart({
  data,
  title,
  dataKey,
  dataFill,
  tooltipSuffix,
  tooltipDataKey,
  showBrush = true,
  domainPadding = 2,
  minValue,
  maxValue,
}: TTemperatureChartProps) {
  const theme = useTheme();

  return (
    <>
      {title ?
        <Typography component={'p'} sx={{ fontWeight: 600 }}>
          {title}
        </Typography> : null
      }
      <ResponsiveContainer width="100%" height="90%">
        <LibBarChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="label"/>
          <YAxis domain={[`dataMin - ${domainPadding}`, `dataMax + ${domainPadding}`]}/>
          <Tooltip content={<ChartTooltip dataKey={tooltipDataKey ? tooltipDataKey : dataKey[0]}
            suffix={tooltipSuffix}
            minValue={minValue}
            maxValue={maxValue}/>}/>
          <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }}/>
          {showBrush && <Brush dataKey="time" height={30} stroke={theme.palette.secondary.light}/>}
          {
            dataKey.map((key) => (<Bar dataKey={key} fill={
              dataFill?.[key] ? dataFill[key] : theme.palette.warning.light
            } stroke={theme.palette.warning.main} fillOpacity={0.5}/>
            ))
          }
          {minValue || minValue === 0 ? <ReferenceLine y={minValue} stroke={theme.palette.success.main} alwaysShow/> : null}
          {maxValue || maxValue === 0 ? <ReferenceLine y={maxValue} stroke={theme.palette.success.main} alwaysShow/> : null}

        </LibBarChart>
      </ResponsiveContainer>
    </>
  );
}
