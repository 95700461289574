import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';


export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1200,
    width: '100%',
    margin: 'auto'
  },
  card: { maxWidth: 400, },
  hidden: { display: 'none', },
  title: {
    margin: theme.spacing(1, 1, 2, 0),
    fontWeight: 600,
  },
  buttonContainer: { marginBottom: theme.spacing(1), },
  formControl: {
    margin: theme.spacing(1, 1, 3, 1),
    minWidth: 120,
    maxWidth: 345,
    display: 'flex',
  },
  formControlButton: {
    margin: theme.spacing(1, 2, 1, 0),
    minWidth: 120,
    maxWidth: 300,
    display: 'flex',
  },
  container: { marginBottom: theme.spacing(4), },
  selectEmpty: { marginTop: theme.spacing(2), },
  fieldset: { marginBottom: theme.spacing(1), },
  checkbox: { marginBottom: theme.spacing(1), },
  radioGroup: {},
  item: { padding: theme.spacing(2) },
  download: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textDecoration: 'none',
    padding: theme.spacing(1, 2.5),
    textTransform: 'uppercase',
    borderRadius: '4px',
    fontSize: '0.9375rem',
    lineHeight: 1.75,
    margin: '0.5rem',
  }
}));

export const useSelectStyle = makeStyles((theme: Theme) => ({
  container: { maxWidth: '100% !important', },
  root: {
    padding: theme.spacing(0.5, 0),
    width: '100%',
    maxWidth: '100%',
  },
}));
