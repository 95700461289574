import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './features/App/App';
import reportWebVitals from './reportWebVitals';

import './index.css';


const isMobileApp = !!(window as any)?.mapp;

TagManager.initialize({
  gtmId: 'GTM-WD8L954',
  // eslint-disable-next-line no-undef
  preview: `${process.env.APP_MAPP_ID}${isMobileApp ? '-mobile' : '-desktop'}`,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
