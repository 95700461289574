import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { BASE_Z_INDEX } from '../../styles/constants';


const useStyles = makeStyles((theme: Theme) => ({
  widgetContainer: {
    zIndex: BASE_Z_INDEX,
    position: 'relative',
    backgroundColor: theme.palette.common.white
  },
  widgetContent: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    '&:last-child': { paddingBottom: theme.spacing(2) }
  },
  active: { borderColor: theme.palette.primary.main, },
  title: { fontSize: 14, },
  widgetTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  widgetSubtitle: {
    fontSize: 12,
    marginTop: theme.spacing(0.5)
  },
  actionInfoText: {
    flexGrow: 1,
    textAlign: 'right',
  },
  content: { minHeight: 126 },
  item: { marginBottom: theme.spacing(2) },
  subtitle: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    fontSize: 20,
    lineHeight: '24px',
    marginLeft: theme.spacing(1.5)
  },
  subtitle2: { fontSize: 14 },
  bottom: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  bgSuccess: { backgroundColor: theme.palette.success.light, },
  success: { color: theme.palette.success.main, },
  bgDanger: { backgroundColor: theme.palette.error.light, },
  danger: { color: theme.palette.error.main, }
}));
export default useStyles;
