import React, { memo } from 'react';

import { EUnit, useLocalization, withTemperatureUnit } from 'hooks';
import { TDeviceDataItemUI } from 'types/device';
import { TThreshold } from 'types/threshold';
import { EMeasureUnit, i18NCommonContext } from 'utils/constants';
import { convertToFahrenheit } from 'utils/formatTemperature';

import { AreaChart } from '../AreaChart/AreaChart';


export type TTemperatureChartProps = {
    chartData: TDeviceDataItemUI[];
    temperatureUnit?: EUnit;
    internal?: boolean;
    threshold?: TThreshold
}

const TemperatureChart = memo(
  ({
    chartData,
    temperatureUnit,
    threshold
  }: TTemperatureChartProps) => {
    const {
      minValue,
      maxValue,
      minValueUI,
      maxValueUI,
    } = threshold || {};
    const { translate } = useLocalization();
    const isCelsius = temperatureUnit === EUnit.Celsius;
    const dataKey = isCelsius ? 'value' : 'valueF';
    const suffix = isCelsius ? EMeasureUnit.TempC : EMeasureUnit.TempF;
    const min = isCelsius ? minValueUI : minValue && convertToFahrenheit(minValue);
    const max = isCelsius ? maxValueUI : maxValue && convertToFahrenheit(maxValue);

    return (
      <AreaChart
        data={chartData}
        tooltipSuffix={suffix}
        dataKey={[dataKey]}
        minValue={min}
        maxValue={max}
        title={`${
          translate(`${i18NCommonContext}.temperature`, 'Temperature')
        } (${suffix})`}
      />
    );
  }
);

export default withTemperatureUnit(TemperatureChart);
