import { createTheme } from '@mui/material';

import { colors } from './colors';


export const hutbotTheme = createTheme({
  palette: {
    primary: { main: colors.phRed, },
    secondary: {
      main: colors.main,
      dark: colors.dark,
      light: colors.light,
    },
    warning: {
      main: colors.warningMain,
      light: colors.warningLight,
    },
    common: {
      white: colors.white,
      black: colors.black,
    },
    success: {
      main: colors.green,
      light: colors.lightGreen
    },
    error: {
      main: colors.phRed,
      light: colors.lightRed
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.grey400,
      500: colors.grey500,
    },
    background: { default: colors.background }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid',
          borderColor: colors.borderMain,
          boxShadow: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: { color: colors.main, },
        subtitle2: {
          color: colors.light,
          fontWeight: 'normal'
        }
      }
    }
  },
});
