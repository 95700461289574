import { map } from 'lodash';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Divider,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem, SelectChangeEvent,
  Stack,
  Toolbar,
} from '@mui/material';
import { IStoreExtended } from '@pizza-hut/hutbot-mapp-sdk-js/lib/types';
import { Button, Select } from '@pizza-hut/hutbot-ui-components';
import clsx from 'clsx';
import {
  MouseEvent, useCallback, useMemo, useState
} from 'react';
import { useRouteMatch, Link } from 'react-router-dom';

import { microAppClient } from 'clients/mapp';
import { ROUTES } from 'features/App/constants';
import {
  EUnit, useTemperatureUnit, useDrawer, useLocalization
} from 'hooks';
import { TStoreSelect } from 'types/store';
import { i18NCommonContext } from 'utils/constants';

import useStyles from './Header.style';


interface IHeaderProps {
    signOut?(): void;
    champsNumber?: string;
    onSelectStore?: (e: SelectChangeEvent) => void;
    stores?: IStoreExtended[];
    showStoreSelect?: boolean;
}

const Header = ({
  showStoreSelect=true, stores, signOut, champsNumber, onSelectStore
}: IHeaderProps) => {
  const {
    locales, currentLocale, version, handleChange: onLocaleChange, translate
  } = useLocalization();
  const match = useRouteMatch();
  const { unit, handleChange } = useTemperatureUnit();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [unitOpen, setUnitOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const toggleUnit = useCallback(() => {
    setUnitOpen(unitOpen => !unitOpen);
  }, []);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { drawerOpen, toggleDrawer } = useDrawer({ open: false });

  const storeOptions: TStoreSelect[] = useMemo(() => {
    return (stores || []).map((store) => ({
      ...store,
      value: store.champsNumber,
      label: `${store.storeName} (${store.champsNumber})`
    }));
  }, [stores]);

  const isDashboard = match?.path?.includes(ROUTES.DASHBOARD);
  const isExport = match?.path?.includes(ROUTES.EXPORT);

  const handleLocaleChange = (event: SelectChangeEvent<string | number | string[]>): void => {
    const selectedLocale = event.target.value;
    const [locale, version] = String(selectedLocale).split('__');
    onLocaleChange({ locale, version: Number(version) });
  };

  return <AppBar position="fixed" className={clsx('not-print', classes.appBar)}>
    <Toolbar className={classes.header}>
      <Stack className={classes.title} direction={'row'}>
        {(showStoreSelect && storeOptions.length) ? (
          <Select
            value={champsNumber}
            onChange={onSelectStore}
            className={classes.headerSelect}
            variant="standard"
            color={'secondary'}
            options={storeOptions}
          />
        ) : null
        }
      </Stack>
      <div>
        <Stack className={'mobile-only'}>
          <IconButton
            color="inherit"
            onClick={toggleDrawer(!drawerOpen)}
            sx={{ color: 'secondary.main' }}
            // className={clsx(classes.headerMenuButton, classes.headerMenuButtonCollapse)}
          >
            {drawerOpen ? <CloseIcon/> : <MenuIcon/>}
          </IconButton>
        </Stack>
        <Stack direction={'row'} className={'desktop-only'}>
          <Button sx={{ color: isDashboard ? 'primary.main': 'secondary.dark' }}>
            {
              isDashboard ? translate(`${i18NCommonContext}.dashboard`, 'Dashboard') :
                <Link className={classes.headerLink} to={ROUTES.DASHBOARD}>
                  {translate(`${i18NCommonContext}.dashboard`, 'Dashboard')}
                </Link>
            }
          </Button>
          {
            !microAppClient.isMobileMicroApp &&
                        <Button sx={{ color: isExport ? 'primary.main' : 'secondary.dark' }}>
                          {
                            isExport ? translate(`${i18NCommonContext}.export`, 'Export') :
                              <Link className={classes.headerLink}
                                to={ROUTES.EXPORT}>{translate(`${i18NCommonContext}.export`, 'Export')}</Link>
                          }
                        </Button>
          }
          <Button
            sx={{ color: 'secondary.dark' }}
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon/>}
          >
            {translate(`${i18NCommonContext}.settings`, 'Settings')}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button', }}
          >
            <MenuItem sx={{ minWidth: 200 }}
              onClick={toggleUnit}>
              <ListItemIcon>
                <DeviceThermostatIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>{translate(`${i18NCommonContext}.temperature.${unit}`, unit)}</ListItemText>
              <ArrowDropDownIcon/>
            </MenuItem>
            <Collapse in={unitOpen} timeout="auto" unmountOnExit>
              <MenuItem selected={unit === EUnit.Celsius} sx={{ pl: 8 }}
                onClick={() => handleChange(EUnit.Celsius)}>
                <ListItemText primary={translate(`${i18NCommonContext}.temperature.Celsius`, 'Celsius')}/>
              </MenuItem>
              <MenuItem selected={unit === EUnit.Fahrenheit} sx={{ pl: 8 }}
                onClick={() => handleChange(EUnit.Fahrenheit)}>
                <ListItemText primary={translate(`${i18NCommonContext}.temperature.Fahrenheit`, 'Fahrenheit')}/>
              </MenuItem>
            </Collapse>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <LanguageIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText><Select
                value={`${currentLocale}__${version}`}
                onChange={handleLocaleChange}
                className={classes.select}
                variant="standard"
                color={'secondary'}
                options={[
                  {
                    value: '',
                    label: translate(`${i18NCommonContext}.selectLanguage`, 'Select language'),
                  },
                  ...map(locales, loc => ({
                    label: loc.locale,
                    value: `${loc.locale}__${loc.version}`,
                  })),
                ]}
              /></ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
              signOut?.();
              handleClose();
            }}>
              <ListItemIcon>
                <LogoutIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>{translate(`${i18NCommonContext}.signOut`, 'Sign out')}</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
        <Drawer
          anchor={'right'}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          className={classes.drawer}
          classes={{ paper: classes.drawer }}
        >
          <div className={classes.mobileBackButton}>
            <IconButton onClick={toggleDrawer(false)}>
              {drawerOpen ? <CloseIcon/> : <MenuIcon/>}
            </IconButton>
          </div>
          <Box
            role="presentation"
            // onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItem button className={clsx(classes.drawerMenuItem, { [classes.activeItem]: isDashboard })}>
                {isDashboard ? translate(`${i18NCommonContext}.dashboard`, 'Dashboard') :
                  <Link className={classes.headerMobileLink} to={ROUTES.DASHBOARD}>{translate(`${i18NCommonContext}.dashboard`, 'Dashboard')}</Link>}
              </ListItem>
              {!microAppClient.isMobileMicroApp &&
                                <ListItem button className={clsx(classes.drawerMenuItem, { [classes.activeItem]: isExport })}>
                                  {isExport ? translate(`${i18NCommonContext}.export`, 'Export') :
                                    <Link className={classes.headerMobileLink}
                                      to={ROUTES.EXPORT}>{translate(`${i18NCommonContext}.export`, 'Export')}</Link>}
                                </ListItem>
              }
              <ListItem button className={classes.drawerMenuItem}>
                {translate(`${i18NCommonContext}.settings`, 'Settings')}
              </ListItem>
              <ListItem button className={classes.drawerMenuItem} onClick={toggleUnit}>
                <ListItemIcon className={classes.drawerMenuIcon}>
                  <DeviceThermostatIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{translate(`${i18NCommonContext}.temperature.${unit}`, unit)}</ListItemText>
                <ArrowDropDownIcon/>
              </ListItem>
              <Collapse in={unitOpen} timeout="auto" unmountOnExit>
                <MenuItem selected={unit === EUnit.Celsius} sx={{ pl: 8 }}
                  onClick={() => handleChange(EUnit.Celsius)}>
                  <ListItemText primary={translate(`${i18NCommonContext}.temperature.Celsius`, 'Celsius')}/>
                </MenuItem>
                <MenuItem selected={unit === EUnit.Fahrenheit} sx={{ pl: 8 }}
                  onClick={() => handleChange(EUnit.Fahrenheit)}>
                  <ListItemText primary={translate(`${i18NCommonContext}.temperature.Fahrenheit`, 'Fahrenheit')}/>
                </MenuItem>
              </Collapse>
              <Divider className={classes.drawerMenuDivider}/>
              <ListItem button className={classes.drawerMenuItem}>
                <ListItemIcon className={classes.drawerMenuIcon}>
                  <LanguageIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  <Select
                    value={`${currentLocale}__${version}`}
                    onChange={handleLocaleChange}
                    className={classes.select}
                    variant="standard"
                    color={'secondary'}
                    options={[
                      {
                        value: '',
                        label: translate(`${i18NCommonContext}.selectLanguage`, 'Select language'),
                      },
                      ...map(locales, loc => ({
                        label: loc.locale,
                        value: `${loc.locale}__${loc.version}`,
                      })),
                    ]}
                  />
                </ListItemText>
              </ListItem>
              <Divider className={classes.drawerMenuDivider}/>
              <ListItem button className={classes.drawerMenuItem} onClick={() => {
                signOut?.();
                handleClose();
              }}>
                <ListItemIcon className={classes.drawerMenuIcon}>
                  <LogoutIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>{translate(`${i18NCommonContext}.signOut`, 'Sign out')}</ListItemText>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </div>
    </Toolbar>
  </AppBar>;
};

export default Header;
