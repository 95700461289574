import { AUTH_TYPE } from 'aws-appsync-auth-link';

import { graphqlClientConfig } from '../features/Config/graphqlClientConfig';

import { THutbotGraphQLClientConfig } from './createClient';


export const createApolloClientConfig = (token: string, brand: string): THutbotGraphQLClientConfig => ({
  url: graphqlClientConfig.urlEndpoint,
  region: graphqlClientConfig.awsRegion,
  auth: {
    type: AUTH_TYPE.AWS_LAMBDA,
    token: (): string => token,
  },
  brand,
});
