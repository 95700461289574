import gql from 'graphql-tag';


export const FIND_ALL_DEVICES_BY_CHAMP_NUMBERS = gql`
  query findAllDevicesByChampNumbers($champsNumber: String!) {
    findAllDevicesByChampNumbers(champsNumber: $champsNumber) {
      items {
        thingName
        thingTypeName
        attributes {
          Brand
          ChampsNumber
          Country
          DeviceId
          Location
        }
        latestDeviceData
      }
    }
  }
`;

export const FIND_ALL_DEVICE_DATA_BY_ID = gql`
  query findAllDeviceDataById(
    $deviceId: String!
    $limit: Int
    $dateRange: DateRangeInput
  ) {
    findAllDeviceDataById(
      deviceId: $deviceId
      limit: $limit
      dateRange: $dateRange
    ) {
      items {
        equipmentType
        measureUnit
        operation
        standardType
        standardVersion
        status
        value
        timestamp
        baselineValue
        thingName
        champsNumber
      }
    }
  }
`;

export const FIND_ALL_THRESHOLD = gql`
  query findAllThresholds {
    findAllThresholds {
      items {
        beltTime
        brand
        champsNumber
        equipmentType
        fuel
        location
        locationType
        maxValue
        market
        minValue
        model
        operation
        standardType
        standardUnit
        thresholdKey
      }
    }
  }
`;
export const FIND_ALL_STORES = gql`
    query findAllStoresByUser {
        findAllStoresByUser {
            items {
                aisOrganizationId
                areaId
                aisStoreId
                businessUnitId
                champsNumber
                countryIsoCode
                timeZone
                testStore
                storeName
                storeAddress
                regionName
                marketId
                internalStoreNumber
                id
            }
        }
    }
`;
