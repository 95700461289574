export enum EDeviceThingTypeNames {
  SigFoxOrbitKTempProbe = 'sigfox-orbit_k-temp_probe',
  SigFoxAyGaWacs = 'sigfox-ayga-wacs',
  SigFoxCO2Sensor = 'sigfox-co2-sensor',
  SigFoxPeopleSense = 'sigfox-people-sense',
  SigFoxPipeTemperatureSimpleIndustry = 'sigfox-pipe_temperature-simple_industry',
  SigFoxDoorSensorSimplePack = 'sigfox-door_sensor-simple_pack',
  TestoSensorQsrTemp = 'testo-sensor-qsr-temp',
  TestoSensorT3Temp = 'testo-sensor-t3-temp',
  TestoSensorQsrDoor = 'testo-sensor-qsr-door',
  TestoSensorWirelessTemp = 'testo-sensor-wireless-temp',
  TestoSensorWirelessDoor = 'testo-sensor-wireless-door',
  TestoSensorT2Temp = 'testo-sensor-t2-temp',
  TestoSensorT2Door = 'testo-sensor-t2-door',
  TestoSensorT3Door = 'testo-sensor-t3-door',
}

export enum EHumanReadableDeviceThingTypeNames {
  'sigfox-orbit_k-temp_probe' = 'Orbit K',
  'sigfox-ayga-wacs' = 'Ayga Wacs',
  'sigfox-co2-sensor' = 'CO2 Sensor',
  'sigfox-people-sense' = 'People Sense',
  'sigfox-pipe_temperature-simple_industry' = 'Pipe Temperature',
  'sigfox-door_sensor-simple_pack' = 'Door Sensor',
  'testo-sensor-qsr-temp' = 'Pipe Temperature',
  'testo-sensor-t3-temp' = 'Pipe Temperature',
  'testo-sensor-qsr-door' = 'Door Sensor',
  'testo-sensor-wireless-temp' = 'Pipe Temperature',
  'testo-sensor-wireless-door' = 'Door Sensor',
  'testo-sensor-t2-temp' = 'Pipe Temperature',
  'testo-sensor-t2-door' = 'Door Sensor',
  'testo-sensor-t3-door' = 'Door Sensor',
}

export enum EDeviceICon {
  CO2 = 1,
  Temperature,
  Humidity,
  DoorStatus,
}

export const DATE_FORMAT = 'DD/MM/YYYY';
export const FULL_DATETIME_FORMAT = 'HH:mm dd/MM/yyyy';
export const SHORT_DATETIME_FORMAT = 'dd/MM HH:mm';

export const AUTH_TOKEN_KEY = 'token';
export const BRAND_KEY = 'brand';

export const LOCALIZATION_DEFAULT_LOCALE = 'en-GB';
export const LOCALIZATION_DEFAULT_DOMAIN = 'admin';
export const LOCALIZATION_DEFAULT_VERSION = -1;
export const LOCALIZATION_STORAGE_KEY = 'LOCALIZATION';
export const i18NCommonContext = 'iot.dashboard.common';

//dashboard detail
export enum EMeasureUnit {
  TempC = '°C',
  TempF = '°F',
  TestoTempC = 'C',
  PPM = 'ppm',
  Percent = '%',
  Times = 'times',
}

export enum EDetailTab {
  CO2 = 'CO2',
  Temperature = 'temperature',
  InternalTemperature = 'internaltemperature',
  Humidity = 'humidity',
  PeopleSense = 'PeopleSense',
  DoorStatus = 'DoorStatus',
}
export function isTempC(measureUnit: string): boolean {
  return (
    measureUnit === EMeasureUnit.TempC ||
    measureUnit === EMeasureUnit.TestoTempC
  );
}
