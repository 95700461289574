import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  titlePage: {
    fontSize: '50px',
    color: '#e52a33',
    lineHeight: '58px',
    fontWeight: 600,
  }
}));

const Forbidden = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.titlePage}>Invalid user permissions</Typography>
    </Box>
  );
};

export default Forbidden;
