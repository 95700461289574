import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

import { BASE_Z_INDEX } from '../../styles/constants';


export type TLoadingOverlayProps = CircularProgressProps & {};

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: BASE_Z_INDEX + 1,
  },
});

/**
 *
 * @description The loading overlay
 * @note Parent container needs to have position relative
 * @returns
 */
export function LoadingOverlay(props: TLoadingOverlayProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress {...props} />
    </div>
  );
}
