import { Card, Typography } from '@mui/material';
import React from 'react';

import { useLocalization } from 'hooks';
import { i18NCommonContext } from 'utils/constants';


type TPayload = {
    payload: {
        [key: string]: string;
        tooltipSubtitle: string
    }
}

interface ICustomTooltip {
    active?: string,
    dataKey: string,
    suffix?: string,
    payload?: TPayload[],
    minValue?: number,
    maxValue?: number,
}

const ChartTooltip = ({
  active, payload, dataKey, suffix, minValue, maxValue
}: ICustomTooltip) => {
  const { translate } = useLocalization();
  if (active && payload && payload.length) {
    return (
      <Card sx={{ p: 1 }}>
        <Typography component={'p'} sx={{ fontWeight: 600, mb: 0.5 }}>
          {`${payload[0].payload[dataKey]} ${suffix || ''}`}
        </Typography>
        <Typography variant={'subtitle2'}>
          {payload[0].payload.tooltipSubtitle}
        </Typography>
        {(minValue || minValue === 0) ?
          <Typography variant={'subtitle2'}>
            {translate(`${i18NCommonContext}.threshold.min`, 'Min threshold')} = {minValue} {suffix}
          </Typography> : null
        }
        {(maxValue || maxValue === 0) ?
          <Typography variant={'subtitle2'}>
            {translate(`${i18NCommonContext}.threshold.max`, 'Max threshold')} = {maxValue} {suffix}
          </Typography> : null
        }
      </Card>
    );
  }

  return null;
};
export default ChartTooltip;
