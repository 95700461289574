import CloudIcon from '@mui/icons-material/Cloud';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { EIconTypes, Icon } from '@pizza-hut/hutbot-ui-components';
import React from 'react';

import { EDeviceICon } from '../../utils/constants';


export type TTemperatureChartProps = {
    title?: string,
    data: any;
};

interface IRenderThingIcon {
    icon: EDeviceICon
}
const ThingIcon = ({ icon }: IRenderThingIcon) => {
  switch (icon) {
    case EDeviceICon.CO2: {
      return <CloudIcon/>;
    }
    case EDeviceICon.Temperature: {
      return <DeviceThermostatIcon/>;
    }
    case EDeviceICon.DoorStatus: {
      return <ToggleOnIcon/>;
    }
    case EDeviceICon.Humidity: {
      return <Icon
        type={EIconTypes.ICON_HUMIDITY}
      />;
    }
    default:
      return null;
  }
};
export default ThingIcon;
