import {
  Box,
  Drawer,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IStoreExtended } from '@pizza-hut/hutbot-mapp-sdk-js';
import React, { KeyboardEvent, MouseEvent } from 'react';

import { TLatestDeviceUI } from 'types/device';

import Detail from './Detail';


const useStyles = makeStyles((theme: Theme) => {
  return {
    title: { flex: 1, },
    drawer: {
      width: '100%',
      zIndex: 1202,
    },
    mobileBackButton: { padding: theme.spacing(1.5, 0), },
    drawerMenuItem: {
      padding: theme.spacing(2, 3),
      color: theme.palette.secondary.main
    },
    activeItem: { color: theme.palette.primary.main },
    drawerMenuDivider: { margin: theme.spacing(0, 3, 0, 5) },
    drawerMenuIcon: {
      minWidth: theme.spacing(1),
      padding: theme.spacing(0, 2)
    }
  };
});

export type TDetailDrawerProps = {
    device: TLatestDeviceUI;
    open: boolean,
    store: IStoreExtended,
    toggleDrawer: (open: boolean) => (event: KeyboardEvent | MouseEvent) => void
}

export default function DetailDrawer({
  device, store, open, toggleDrawer
}: TDetailDrawerProps) {
  const classes = useStyles(false);

  return <Drawer
    anchor={'right'}
    open={open}
    onClose={toggleDrawer(false)}
    className={classes.drawer}
    classes={{ paper: classes.drawer }}
  >
    <Detail store={store} device={device} inDrawer={true} handleCloseDrawer={toggleDrawer(false)}/>
    <Box
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
    </Box>
  </Drawer>;
}
